.step_main_sec {
  padding-top: 150px;
  padding-bottom: 60px;
}

.step_inner_sec {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.stes_box_item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  background-color: #292929;
  border-radius: 40px;
  padding: 30px;
  margin-top: 40px;

  // third step
  &.stes_box_item_third {
    max-height: 550px;
    overflow: auto;
    grid-template-columns: repeat(4, 1fr);

    h5 {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.3;
      font-family: 'Inter-Medium';
      padding-top: 15px !important;
    }

    .items {
      .img-wrapper {
        position: relative;

        .main-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
          max-height: 200px;
          border-radius: 10px;
        }

        .tick-icon {
          position: absolute;
          top: 10px;
          left: 10px;
        }
      }
    }
  }

  //fourth step
  &.stes_box_item_fourth {
    max-height: 400px;
    overflow: auto;
    grid-template-columns: repeat(4, 1fr);

    .items {
      display: flex;
      align-items: center;
    }

    h5 {
      font-size: 14px;
      font-family: 'Inter-Medium';
      padding:5px 10px !important;
    }
    img{
      display: flex;
      height: 30px;
      width: 30px;
    }
  }

}

.sales-tag {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &.sales-tag span {
    font-family: 'Inter-Regular';
    opacity: 70%;
  }
}

.stes_box_item .items {
  border: 2px solid rgba(255, 255, 255, 0.02);
  border-radius: 24px;
  padding: 20px;
  background-color: #323232;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;

  &.selected-item {
    border: 2px solid goldenrod;
  }

  &.unselected-item {
    border: 2px solid #323232;
  }
}

.stes_box_item .items:hover {
  background-color: #444444;
}

.stes_box_item .items h5 {
  padding-top: 25px;
}

.step_inner_sec h2 {
  font-family: 'clashdisplay-semibold';
  font-size: 45px;
  line-height: 55px;
  font-weight: 600;
  max-width: 400px;
}

.step_sidebar {
  max-width: 382px;
}

.step_btn_sec a {
  font-size: 20px;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-family: 'Inter-SemiBold';
  padding: 16px 34px;
  display: inline-block;
}

.step_btn_sec a.step_btn_back {
  background-color: rgba(29, 29, 29, 1);
}

.step_btn_sec a.step_btn_next {
  background-color: rgba(255, 180, 58, 1);
}

.step_btn_sec {
  display: flex;
  gap: 30px;
  padding-top: 50px;
}

/*  Secound Step  */
.second_step_sec .step_inner_sec h2 {
  max-width: 100%;
}

.industry_box select {
  width: 100%;
  max-width: 630px;
  background-color: rgba(48, 48, 48, 1);
  border-radius: 12px;
  color: rgba(255, 255, 255, 1);
  padding: 16px;
}

.industry_box {
  padding-top: 25px;
}

//Fifth 
.stes_box_item_fifth{
  background-color: #292929;
  border-radius: 40px;
  padding: 30px;
  margin-top: 40px;
  max-height: 450px;
  overflow: auto;
  .user-input{
    margin-top: 3px;
    margin-bottom: 10px;
    border-radius: 3px;
    border-radius: 5px !important;
  }
  textarea{
    resize: none;
    height: 150px;
  }
  .icons{
    display: flex;
    height: 50px;
    img{
      height: 50px;
    }
  }
}

//first
@media screen and (max-width: 765px) {
  .stes_box_item {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 15px;
    margin-top: 25px;
    border-radius: 12px;
  }

  .step_inner_sec h2 {
    font-size: 34px;
    line-height: 40px;
  }

  .stes_box_item .items h5 {
    padding-top: 12px;
    font-size: 14px;
    line-height: normal;
  }

  .stes_box_item .items {
    padding: 20px 15px;
    border-radius: 8px;
  }

  .step_inner_sec {
    gap: 25px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .step_sidebar {
    max-width: 100%;
  }
}
//third
@media screen and (max-width: 990px) {
  .stes_box_item {
    &.stes_box_item_third{
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 15px;
    margin-top: 25px;
    border-radius: 12px;
  }}
}

@media screen and (max-width: 765px) {
  .stes_box_item {
    &.stes_box_item_third{
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 15px;
    margin-top: 25px;
    border-radius: 12px;
  }}
  .step_btn_sec{
    padding: 15px 50px;
  }
  .step_btn_sec a {
    font-size: 15px;
    padding: 10px 24px;
  }
}

  @media screen and (max-width: 450px) {
    .stes_box_item {
      &.stes_box_item_third{
      grid-template-columns: repeat(1, 1fr);
      gap: 12px;
      padding: 15px;
      margin-top: 25px;
      border-radius: 12px;
    }}
    .step_btn_sec{
      padding: 15px 50px;
    }
    .step_btn_sec a {
      font-size: 15px;
      padding: 10px 14px;
    }
}

//fourth
@media screen and (max-width: 990px) {
  .stes_box_item {
    &.stes_box_item_fourth{
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 15px;
    margin-top: 25px;
    border-radius: 12px;
  }}
}

@media screen and (max-width: 765px) {
  .stes_box_item {
    &.stes_box_item_fourth{
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 15px;
    margin-top: 25px;
    border-radius: 12px;
  }}
  .step_btn_sec{
    padding: 15px 50px;
  }
  .step_btn_sec a {
    font-size: 15px;
    padding: 10px 24px;
  }
}

  @media screen and (max-width: 450px) {
    .stes_box_item {
      &.stes_box_item_fourth{
      grid-template-columns: repeat(1, 1fr);
      gap: 12px;
      padding: 15px;
      margin-top: 25px;
      border-radius: 12px;
    }}
    .step_btn_sec{
      padding: 15px 50px;
    }
    .step_btn_sec a {
      font-size: 15px;
      padding: 10px 14px;
    }
}
/*  Secound Step  */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .step_inner_sec h2 {
    font-size: 38px;
    line-height: 42px;
    max-width: 340px;
  }

  .stes_box_item {
    padding: 15px;
    margin-top: 15px;
    gap: 10px;
    border-radius: 24px;
  }

  .stes_box_item .items {
    padding: 20px 15px;
    border-radius: 16px;
  }

  .stes_box_item .items h5 {
    padding-top: 10px;
    font-size: 16px;
  }

  .step_inner_sec {
    gap: 0;
    align-items: center;
  }
}