@import '../../assets/scss/abstracts/variables';

.AuthWrapper {
  background: #000 url('../../assets/images/background/bkg-1.webp');
  background-repeat: repeat-y;
  background-position: 0 0;
  background-size: 100% auto;
  animation: 145s backgroundloop infinite linear;
  position: relative;
  background-attachment: fixed;
  height: 100vh;
  margin-bottom: 0;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%),
      linear-gradient(
        180deg,
        rgba(20, 20, 20, 0.6) 0%,
        rgba(20, 20, 20, 0.11) 21.88%,
        rgba(20, 20, 20, 0) 100%
      ),
      linear-gradient(
        271deg,
        rgba(20, 20, 20, 0.09) 0.81%,
        rgba(20, 20, 20, 0.72) 68.38%,
        rgba(20, 20, 20, 0.846) 99.09%
      );
    left: 0;
    top: 0;
  }

  .auth_wrapper {
    height: 100%;

    .authDiv {
      height: calc(100% - 76px);
      display: flex;
      align-items: center;
      justify-content: center;

      .authBlock {
        position: relative;
        top: auto;
        left: auto;
        transform: unset;
        z-index: 9;
        background: rgba(0, 0, 0, 0.7);
        padding: 60px;
        width: 100%;
        max-width: 480px;
      }
    }
  }

  .logo_content {
    position: relative;
    z-index: 999;
    font-size: 0;
    line-height: 0;
    padding: 24px 0;

    @media screen and (max-width: 991px) {
      a {
        margin: auto;
        display: block;
        max-width: max-content;
      }
    }

    @media (max-width: 575px) {
      padding: 18px 0;
    }
  }

  .authBlock {
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 60px;
    width: 100%;
    max-width: 480px;

    @media (max-width: 991px) {
      padding: 40px;
    }

    @media (max-width: 767px) {
      padding: 30px;
    }

    @media (max-width: 575px) {
      max-width: 95%;
      padding: 20px;
    }

    .heading {
      margin-bottom: 16px;
    }
  }

  .text_block {
    text-align: center;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 15px;
    margin-top: 20px;
    margin-bottom: 0px;

    @media (max-width: 380px) {
      flex-direction: column;
      gap: 5px;
    }

    a {
      margin-left: 5px;
      padding: 0;
      color: $primary-color;
    }
  }

  button {
    position: relative;
    min-width: 175px;
    padding: 12px 24px;
    border: 1px solid transparent;
    font-family: 'Inter-SemiBold';
    font-weight: 300;
    transition: 0.5s;
    letter-spacing: 1px;
    display: flex;
    background-color: #ffb43a !important;
    transition: all 0.5s ease;
    text-transform: uppercase;
    text-align: center;
    color: #fff !important;
    border-radius: 6px;
    justify-content: center;
    flex-direction: row-reverse;
    overflow: hidden;
    width: 100%;
    margin-top: 15px;
    > div {
      position: relative;
      left: 0;
      right: 0;
      transform: inherit;
      margin-left: 10px;
      color: #fff !important;
    }
    &:hover {
      border-color: $primary-color !important;
      background-color: transparent !important;
      color: $primary-color !important;
      > div {
        color: $primary-color !important;
      }
    }
    @media (max-width: 575px) {
      padding: 6px 24px;
    }
  }
  .or_block {
    text-align: center;
    margin-top: 8px !important;
    span {
      font-size: 15px;
    }
  }
  .google-signin {
    margin-top: 6px !important;
    width: 100%;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: #fff !important;
      color: #000 !important;
      border-color: #fff !important;
      font-size: 0.875rem;
      line-height: 1.75;
      margin-top: 0;
      .MuiLoadingButton-loadingIndicator {
        color: #000 !important;
        margin-left: 0;
      }
      .insider {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000 !important;
        gap: 10px;
      }
      &:hover {
        background: transparent !important;
        color: $primary-color !important;
        .MuiLoadingButton-loadingIndicator {
          color: #fff !important;
        }
        .insider {
          color: #fff !important;
        }
      }
    }
    iframe {
      margin: 0 auto !important;
      width: 100% !important;
    }
  }
  .input-wrapper {
    position: relative;

    img {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      opacity: 0.6;
      cursor: pointer;
    }
  }
}
