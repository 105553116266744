@import '../../assets/scss/abstracts/variables';
p.home_about_sub_title {
  margin-bottom: 0;
  color: #fff;
  opacity: 1;
}
sapn.title_yellow_color {
  color: #ffb43a;
}
/* NORMAL SECTION CSS START HERE */
.normal_wrapper {
  margin-bottom: 100px;
  padding-top: 70px;

  @media screen and (max-width: 1399px) {
    padding-top: 30px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 10px;
    margin-bottom: 40px;
  }

  .heading {
    padding-right: 50px;

    @media screen and (max-width: 991px) {
      padding-right: 0;
      margin-bottom: 40px;
    }
  }

  .flex-row-reverse {
    .heading {
      padding-right: 0px;
      padding-left: 50px;

      @media (max-width: 991px) {
        padding-left: 0;
      }
    }
  }
}

/* NORMAL SECTION CSS END'S HERE */

/* FEATURE SECTION CSS START HERE */
.features_wrapper {
  // padding-top: 40px;
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }

  .heading {
    margin-bottom: 40px;
  }

  .features_block {
    // background: $secondary-color;
    border-radius: 15px;
    // padding: 20px;

    .features_images {
      // width: 138px;
      // height: 138px;
      margin: 0 auto 26px;
      // background: url(../../assets/images/icon/bkg-icon.svg) no-repeat center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .features_text {
      text-align: center;

      h2 {
        font-family: $Inter-SemiBold;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        color: $white;
        margin-bottom: 0px;

        @media screen and (max-width: 1399px) {
          font-size: 20px;
          line-height: 28px;
        }

        @media screen and (max-width: 1199px) {
          font-size: 18px;
          line-height: 26px;
        }
      }

      p {
        opacity: 0.7;

        @media screen and (max-width: 1199px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

/* FEATURE SECTION CSS END'S HERE */

.timeline-item {
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 12px;

  margin: 0 auto;
  max-width: 100%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -20px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #ccd1d9;
  background: linear-gradient(to right, #f5f5f4 8%, #e7e6e6 18%, #f5f5f4 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}

// .features_wrapper {
//   .swiper-wrapper {
//     .features_images {
//       > span{
//           width: 100%;
//       }
//       .react-loading-skeleton {
//         aspect-ratio: 1/0.5 !important;
//       }
//     }

//     h2 {
//       .react-loading-skeleton {
//         aspect-ratio: 0 !important;
//       }
//     }
//   }
// }







// @import '../../../assets/scss/abstracts/variables';

.Blog{
    overflow: hidden;
    .container{
        // max-width: 1320px;
        // margin: 0 auto;
        // width: 100%;
        // @media screen and (max-width:1365px) {
        //     max-width: 1120px;
        // }
        // width: 82.24%;
        width: 100%;
        max-width: 100%;
        padding: 0 8.88%;
        @media screen and (max-width: 1260px){
            padding: 0 15px;
        }
      }
}
// .Blog_wrapper{
//     background: #090829;
//     position: relative;
//     overflow: hidden;
//     height: 100%;
//     padding: 100px 0 235px;
//     z-index: 2;
//     &:after,&:before{
//         content: "";
//         height: 375px;
//         width: 375px;
//         position: absolute;
//     }
//     &:after{
//         background: #ED1C24;
//         opacity: 1;
//         filter: blur(224px);
//         right: -200px;
//         bottom: -200px;
//     }
//     &::before{
//         background: #FFB43A;
//         opacity: 1;
//         filter: blur(144px);
//         top: -200px;
//         left: -200px;
//     }
//     > div{
//         position: relative;
//         z-index: 2;
//     }
//     .title{
//         h2{
//             color: $white;
//         }
//     }
//     @media screen and (max-width:1480px) {        
//         padding: 70px 0 235px;
//     }
//     @media screen and (max-width:991px) {        
//         padding: 50px 0 160px;
//     }
//     @media screen and (max-width:991px) {
//         .title{
//             text-align: center;
//         }
//     }
// }
.blog_slider{
    margin-top: 50px;
    position: relative;
    z-index: 2;
    .swiper {
        margin: 0  8.88%;
        overflow: visible;
        padding-bottom: 50px;
    }    
    .swiper-slide {
        font-size: 18px;
        background: transparent;
        .blog_images{
            height: 360px;
            max-width: 645px;
            margin-bottom: 40px;
            overflow: hidden;
            background-color: #FFF;
        }
        .blog_text{
            max-width: 645px;
            h1,h2,h3,h4,h5,h6{
                display: inline-block;
                // font-family: $Helvetica-Neue-Medium;
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                line-height: 33px;
                color: white;
                margin-bottom: 28px;
                transition: color 0.5s ease;
            }
            label{
                // font-family: $Helvetica-Neue-Medium;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 24px;
                color: #989EAA;
                display: block;
            }
        }
        a{
            &:hover{
                h1,h2,h3,h4,h5,h6{
                    color: white;
                }                
            }
        }
    }    
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper-scrollbar {
        position: absolute;
        left: 35%;
        bottom: 0;
        z-index: 50;
        height: 2px;
        width: 30%;
    }
    @media screen and (max-width:1480px) {
        .swiper-slide {
            .blog_images{
                height: 280px;
                max-width: 480px;
                margin-bottom: 20px;
            }
            .blog_text{
                h1,h2,h3,h4,h5,h6{
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 10px;
                }
                label{
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }   
    }
    @media screen and (max-width:1260px) {
        .swiper{
            margin: 0 15px;
        }
        .swiper-slide {
            .blog_images{
                height: auto;
                max-width: 100%;
                margin-bottom: 20px;
            }
        } 
    }
    @media screen and (max-width:991px) {
        margin-top: -120px;
        .swiper-slide {
            .blog_images{
                margin-bottom: 10px;
            }
            .blog_text{
                h1,h2,h3,h4,h5,h6{
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 5px;
                }
                label{
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
}


