@import '../../assets/scss/abstracts/variables';
// .title_block {
//   padding-top: 152px;
//   padding-bottom: 80px;
//   text-align: center;
//   @media screen and (max-width: 767px) {
//     padding-top: 100px;
//     padding-bottom: 30px;
//   }
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     font-family: $clashdisplay-semibold;
//     font-weight: 600;
//     font-size: 42px;
//     line-height: 52px;
//     color: $white;
//     margin-bottom: 14px;
//     @media screen and (max-width: 1399px) {
//       font-size: 38px;
//       line-height: 48px;
//     }
//     @media screen and (max-width: 1199px) {
//       font-size: 32px;
//       line-height: 44px;
//     }
//   }
// }



// .blog-style-one {
//   position: relative;
//   padding: 5px;
//   border-radius: 10px;
//   overflow: hidden;
//   height: 320px;
//   display: flex;
//   flex-direction: column;

//   @media (max-width:767px) {
//     height: 230px;
//   }
//   a.link{
//     display: block;
//     height: 100%;
//     .bgImg {
//       position: absolute;
//       left: 0;
//       top: 0;
//       width: 100%;
//       height: 100%;
//       object-position: center;
//       object-fit: cover;
//       z-index: -1;
//     }
//   }

//   .live {
//     display: block;
//     position: absolute;
//     right: 10px;
//     top: 10px;
//     background-color: transparent;
//     color: #e8e8e8;
//     font-size: 12px;
//     font-weight: 600;
//     border-radius: 6px;
//     width: 90px;
//     height: 35px;
//     border: none;
//     text-transform: uppercase;
//     cursor: pointer;
//     overflow: hidden;
//     box-shadow: 0 10px 20px rgba(51, 51, 51, 0.2);
//     transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
//     margin-left: auto;

//     &::before {
//       content: 'Preview';
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 100%;
//       height: 100%;
//       pointer-events: none;
//       background: linear-gradient(135deg, #ffc107, #ffb43a);
//       transform: translate(0%, 90%);
//       z-index: 99;
//       position: relative;
//       transform-origin: bottom;
//       transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
//       color: #000;
//     }

//     &::after {
//       content: 'Live';
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       background-color: #333;
//       width: 100%;
//       height: 100%;
//       pointer-events: none;
//       transform-origin: top;
//       transform: translate(0%, -100%);
//       transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
//     }
//     &:hover::before {
//       transform: translate(0%, 0%);
//     }
//     &:hover::after {
//       transform: translate(0%, -200%);
//     }
//     &:active {
//       scale: 0.95;
//     }
//   }
//   &:hover{
//     .blog-content{
//         background:#2c2a2a 
//     }
//   }

//   .blog-content {
//     background: #000000ba;
//     backdrop-filter: blur(3px);
//     border-radius: 10px;
//     padding: 10px;
//     margin-top: auto;
//     transition: all 0.2s ease;

//     .top {
//       display: flex;
//       align-items: center;

//       .cartBtn {
//         position: relative;
//         display: flex;
//         align-items: center;
//         gap: 5px;
//         .plus {
//           position: absolute;
//           right: -3px;
//           top: -11px;
//           color: #fff;
//           line-height: normal;
//           font-style: normal;
//         }
//         label {
//           font-size: 12px;
//           color: #fff;
//           cursor: pointer;
//         }
//       }

//       span {
//         margin-left: auto;
//         color: #fff;
//         font-size: 16px;
//         font-weight: 600;
//       }
//     }
//     .blog-title {
//       display: block;
//       a {
//         font-weight: 600;
//         color: #fff;
//         -webkit-transition: all 0.3s ease-out 0s;
//         -moz-transition: all 0.3s ease-out 0s;
//         -ms-transition: all 0.3s ease-out 0s;
//         -o-transition: all 0.3s ease-out 0s;
//         transition: all 0.3s ease-out 0s;
//         line-height: normal;
//         font-size: 14px;
//       }
//     }
//   }
// }



input{
  caret-color: #fff;
}
.gap-y-24 {
  gap: 24px 0;
}

.productCategory {
  .single-blog {
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    @media (max-width: 767px) {
      width: 100%;
      display: block;
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .link {
    display: block;
    font-size: 0;
    line-height: 0;
    border-radius: 8px;
    overflow: hidden;

    img {
      border-radius: 10px;
    }
  }

  .category-bottom {
    width: 100%;
    border-radius: 0 0 8px 8px;
    // background: linear-gradient(180deg, rgb(22 24 31 / 2%), rgb(22 24 31) 24.61%, rgb(22 24 31) 38.57%, rgb(22, 24, 31) 64.27%, rgb(22, 24, 31) 82.94%, #16181f);
    background: linear-gradient(180deg, rgba(22, 24, 31, 0.02), rgb(22, 24, 31) 64.27%, rgb(22, 24, 31) 82.94%);
    // background: #000000ba;
    backdrop-filter: blur(3px);
    margin: 0;
    padding: 0;
    transition: all 0.5s ease;
    z-index: 5;
    position: absolute;
    left: 0;
    bottom: 0;

    .tray-cardDescription {
      padding: 20px 12px 12px;

      .cartBtn {
        background: #ffffff26;
        margin-left: 8px;
      }

      .cartBtn,
      .previewBtn {
        padding: 6px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        display: flex;
        transition: all 0.5s ease;
        text-transform: capitalize;
        color: #0f1014;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        overflow: hidden;
        position: relative;
        width: 35px;
        height: 35px;
        // &:hover{
        //     transform: scale(1.01);
        // }
      }

      .previewBtn {
        background: #e1e6f0;
        width: calc(100% - 43px);
      }
    }

    .Title {
      margin: 0 0 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 15px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
          color: #fff;
        }
      }

      span {
        font-size: 15px;
        color: $primary-color;
        font-weight: 600;
        letter-spacing: 1px;
        margin-left: 5px;
        line-height: 1.2;
      }
    }
  }
}

.cardBlock {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  padding-top: 150%;
  // height: 330px;
  // @media screen and (max-width:1899px) {
  //     height: 300px;
  // }
  // @media screen and (max-width:1799px) {
  //     height: 290px;
  // }
  // @media screen and (max-width:1699px) {
  //     height: 250px;
  // }
  // @media screen and (max-width:1599px) {
  //     height: 230px;
  // }
  // @media screen and (max-width:1399px) {
  //     height: 300px;
  // }
  // @media screen and (max-width:1299px) {
  //     height: 280px;
  // }

  .cardImg {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    display: block;
    height: 100%;
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    img {
      // display: block;
      // width: 100%;
      // height: 100%;
      // object-fit: cover;
      // object-position: top;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  // &:hover{        
  //     box-shadow: 1px 31px 23px 1px rgba(0,0,0,.57);
  //     .tray-cardHover{
  //         bottom: 8px;
  //     }
  // }  
}

.hover-card-container {
  // @media screen and (min-width:2499px) {
  //     width: 24vw;       
  // }
  // @media screen and (min-width:2800px) {
  //     width: 26vw;       
  // }
  // @media screen and (min-width:3200px) {
  //     width: 30vw;       
  // }
  width: 22vw;
  // min-width: 340px;
  // max-width: 340px;
  height: auto;
  // padding: 12px;
  border-radius: 10px;
  box-shadow: 0 0 13px 2px #000;
  // background-image: linear-gradient(180deg, #232931 43%, #222b37 86%);
  background-color: #222b37;
  // border: 1px solid #222b37;
  backdrop-filter: blur(11px);
  position: absolute;
  right: unset !important;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  @media screen and (max-width:1599px) {
    width: 25vw;
  }

  @media screen and (max-width:1199px) {
    width: 30vw;
  }

  // @media screen and (max-width:1599px) {
  //     min-width: 280px;
  //     max-width: 280px;
  // }

  &.popup {
    left: 50% !important;
  }

  &.popup-left {
    left: 50px !important;
    /* Adjust the value as needed */
  }

  &.popup-right {
    left: -50px !important;
    /* Adjust the value as needed */
  }

  a {
    overflow: hidden;
    display: block;
  }

  .card-image-content {
    position: relative;
    overflow: hidden;
    outline: none !important;

    // &::after {
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     background-image: linear-gradient(180deg, transparent, #222b37 100%);
    //     z-index: 1;
    // }

    .cardImg {
      height: 100%;
      border-radius: 10px 10px 0 0;
      min-height: 238px;

      @media screen and (max-width: 1699px) {
        min-height: 208px;
      }

      @media screen and (max-width: 1399px) {
        min-height: 196px;
      }

      @media screen and (max-width: 1199px) {
        min-height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }

  .card-text-wrapper {
    padding: 20px;

    @media screen and (max-width: 1199px) {
      padding: 10px;
    }
  }

  .btn-wrapper {
    position: relative;
    // margin-bottom: 20px;
    z-index: 2;

    @media screen and (max-width: 1199px) {
      padding: 10px;
    }

    .cartBtn {
      width: 45px;
      height: 45px;
      background-color: transparent;
      border: 1px solid $white;
      font-size: 0 !important;
      font-weight: normal !important;
      line-height: 0 !important;

      img {
        width: 100%;
        max-width: 25px;
        min-width: 25px;
      }
    }

    .previewBtn {
      // background: rgba(255, 255, 255, 0.1490196078);
      color: #fff;
      font-weight: 100;
      min-width: 140px;
      padding: 5px;
      border: 1px solid $white;
      display: flex;

      @media screen and (max-width:1799px) {
        min-width: 100px;
        padding: 10px 8px;
      }

      img {
        width: 100%;
        max-width: 25px;
        min-width: 25px;
      }
    }

    .shareBtn {
      color: #fff;
      font-weight: 100;
      padding: 5px 20px;
      border: 1px solid $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      line-height: 20px;
    }

    .cartBtn,
    .previewBtn,
    .favouriteBtn,
    .shareBtn {
      transform: scale(1) !important;
      border-radius: 6px;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    .favouriteBtn {
      // background: rgba(255, 255, 255, 0.1490196078);
      min-width: 45px;
      width: 45px;
      height: 45px;
      color: #fff;
      font-weight: 100;
      border: 1px solid $white;
      font-size: 0 !important;
      font-weight: normal !important;
      line-height: 0 !important;

      img {
        width: 100%;
        max-width: 25px;
        min-width: 25px;
      }
    }
  }

  .card-text-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 20px;

    span {
      font-size: 24px;
      display: flex;
      align-items: center;
      color: $primary-color;

      @media screen and (max-width:1799px) {
        font-size: 20px;
      }

      i {
        font-size: 16px;
        text-decoration: line-through;
        opacity: 0.6;
        margin-left: 8px;
        font-style: normal;
        color: $white;

        @media screen and (max-width:1799px) {
          font-size: 14px;
          margin-right: 4px;
        }

        &.total-downloads {
          text-decoration: none;
          margin-left: auto;
        }
      }
    }
  }

  .tag-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;

    .tag {
      background: rgba(255, 255, 255, 0.1490196078);
      color: $white;
      font-size: 10px;

      padding: 0 10px;
      border-radius: 30px;
    }
  }

  .Title {
    h2 {
      font-weight: 500;
      font-size: 20px;
      color: $white;
      line-height: 26px;

      @media screen and (max-width:1599px) {
        font-size: 18px;
      }
    }
  }

  .discripion {
    margin-bottom: 20px;

    p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 14px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.6);

      @media screen and (max-width:1599px) {
        -webkit-line-clamp: 3;
        line-clamp: 3;
      }
    }
  }
}