@import "../../../assets/scss/abstracts/variables";

.slider_wrapper {
    position: relative;
    z-index: 4;
    // margin-bottom: 60px;
    margin-bottom: 30px;
    overflow: hidden;

    .container {
        max-width: 100%;
        padding: 0 60px;

        @media screen and (max-width:1260px) {
            // padding: 0 30px;
        }

        @media screen and (max-width:1024px) {
            padding: 0 12px;
            max-width: 960px;
        }
    }

    // &.trending_wrapper {
    //     margin-top: -160px;

    //     @media (max-width:767px) {
    //         margin-top: 0;
    //     }
    // }

    &.NewBestsellers_wrapper {
        margin-top: -140px;

        @media (max-width:767px) {
            margin-top: 0px;
        }
    }

    a {
        .viewIcon {
            width: 24px;
            height: 24px;
            display: flex;
            margin-left: 10px;
            transition: all 0.5s ease;

            span {
                color: $primary-color;
                width: 0px;
                overflow: hidden;
                transition: all 0.5s ease;
                text-wrap: nowrap;
            }

            @media (max-width:575px) {
                display: none !important;
            }
        }

        &:hover {
            .viewIcon {
                width: 124px;
                transition: all 0.5s ease;

                span {
                    width: 60px;
                    margin-right: 5px;
                    transition: all 0.5s ease;
                }
            }
        }

        &.viewBtn {
            display: none !important;

            @media (max-width:575px) {
                display: block !important;
            }
        }
    }
}

.cardSlider {
    overflow: visible !important;
    display: flex;
    margin: 10px -5px 0;

    .swiper-button-next {
        background: linear-gradient(269.25deg, #0f1014 0.62%, rgba(15, 16, 20, 0) 99.33%);
    }

    .swiper-button-prev {
        background: linear-gradient(-269.25deg, #0f1014 0.62%, rgba(15, 16, 20, 0) 99.33%);
    }

    .swiper-button-prev,
    .swiper-button-next {
        height: 100%;
        margin-top: 0;
        top: 0;
        width: 3.4rem;
        margin: 0 -70px;

        @media screen and (max-width:1260px) {
            // width: 3rem;
            // margin: 0 -40px;
            width: 3.5rem;
            margin: 0 -70px;
        }

        @media screen and (max-width:1024px) {
            display: none;
        }

        @media screen and (max-width:768px) {
            margin: 0 -54px;
        }

        &::after {
            color: #fff;
            height: 32px;
            width: 32px;
            font-size: 30px;
            text-align: center;

            @media screen and (max-width:1260px) {
                height: 20px;
                width: 20px;
                font-size: 20px;
            }
        }

        &.swiper-button-disabled {
            opacity: 0;
        }
    }

    .swiper-slide {
        // flex: 1 1 0px;
        transition: transform 300ms ease 100ms;

        // margin: 0 !important;
        // padding: 0 5px;
        // &:after {
        //     content: "";
        //     display: block;
        //     padding-top: 70%;
        // }
        // TOUCH
        @media screen and (max-width:768px) {

            // transform: inherit;
            &.swiper-slide-active {
                .tray-cardHover {
                    bottom: -110% !important;
                }
            }
        }
    }

}

.cardBlock {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    padding-top: 150%;
    // height: 330px;
    // @media screen and (max-width:1899px) {
    //     height: 300px;
    // }
    // @media screen and (max-width:1799px) {
    //     height: 290px;
    // }
    // @media screen and (max-width:1699px) {
    //     height: 250px;
    // }
    // @media screen and (max-width:1599px) {
    //     height: 230px;
    // }
    // @media screen and (max-width:1399px) {
    //     height: 300px;
    // }
    // @media screen and (max-width:1299px) {
    //     height: 280px;
    // }

    .cardImg {
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
        display: block;
        height: 100%;
        display: block;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        img {
            // display: block;
            // width: 100%;
            // height: 100%;
            // object-fit: cover;
            // object-position: top;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    // &:hover{        
    //     box-shadow: 1px 31px 23px 1px rgba(0,0,0,.57);
    //     .tray-cardHover{
    //         bottom: 8px;
    //     }
    // }  
}

.tray-cardHover {
    position: absolute;
    bottom: -110%;
    width: 100%;
    overflow: hidden;
    // background: #16181f;
    // background: linear-gradient(180deg, rgba(22, 24, 31, 0), rgba(22, 24, 31, 0.15) 24.61%, rgba(22, 24, 31, 0.6) 38.57%, rgb(22, 24, 31) 64.27%, rgb(22, 24, 31) 82.94%, #16181f);
    // border-radius: 0px;
    background: linear-gradient(180deg, rgb(22 24 31 / 2%), rgb(22 24 31) 24.61%, rgb(22 24 31) 38.57%, rgb(22, 24, 31) 64.27%, rgb(22, 24, 31) 82.94%, #16181f);
    margin: 0;
    padding: 0;
    // opacity: 0;
    // visibility: hidden;
    transition: all 0.5s ease;
    z-index: 5;

    .tray-cardImg {
        width: 100%;
        height: 190px;
        position: relative;

        &::after {
            content: "";
            background: linear-gradient(180deg, rgba(22, 24, 31, 0), rgba(22, 24, 31, .15) 24.61%, rgba(22, 24, 31, .3) 38.57%, rgba(22, 24, 31, .75) 64.27%, rgba(22, 24, 31, .92) 82.94%, #16181f);
            bottom: 0;
            bottom: -1px;
            height: min(60%, 5rem);
            position: absolute;
            width: 100%;
            left: 0;
        }

        a {
            width: 100%;
            height: 100%;
            display: block;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }

    .tray-cardDescription {
        padding: 20px 12px 12px;

        .previewBtn {
            background: #e1e6f0;
            width: 100%;
        }

        .cartBtn {
            background: #ffffff26;
            margin-left: 8px;

            &.incart {
                background: #ffb43a;
            }
        }

        .cartBtn,
        .previewBtn,
        .favouriteBtn {
            padding: 9px;
            border-radius: 50px;
            justify-content: center;
            align-items: center;
            display: inline-flex;
            transition: all 0.5s ease;
            text-transform: capitalize;
            color: #0f1014;
            font-size: 13px;
            font-weight: 600;
            line-height: 20px;
            overflow: hidden;
            position: relative;

            // &:hover {
            //     transform: scale(1.01);
            // }
        }

        .btn_wrapper {
            display: none;
        }

        .discripion {
            margin-top: 5px;

            ul {
                display: flex;
                align-items: center;

                li {
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 15px;
                    color: #ffffffd6;
                    position: relative;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;

                    &:after {
                        content: "";
                        width: 5px;
                        height: 5px;
                        background: #ffffffd6;
                        display: block;
                        border-radius: 100%;
                        margin: 0 6px;
                    }

                    &:last-child {
                        &::after {
                            content: none;
                        }
                    }
                }
            }

            p {
                font-size: 12px !important;
                font-weight: 400;
                line-height: 18px;
                color: #8f98b2;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }

    .Title {
        margin: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 15px;
            max-width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $white;
        }

        span {
            font-size: 15px;
            color: $primary-color;
            font-weight: 600;
            letter-spacing: 1px;
            margin-left: 5px;
            line-height: 1.2;
        }
    }

    @media screen and (max-width:768px) {
        bottom: -110% !important;
    }
}

.hover-card-container {
    // @media screen and (min-width:2499px) {
    //     width: 24vw;       
    // }
    // @media screen and (min-width:2800px) {
    //     width: 26vw;       
    // }
    // @media screen and (min-width:3200px) {
    //     width: 30vw;       
    // }
    width: 22vw;
    // min-width: 340px;
    // max-width: 340px;
    height: auto;
    // padding: 12px;
    border-radius: 10px;
    box-shadow: 0 0 13px 2px #000;
    // background-image: linear-gradient(180deg, #232931 43%, #222b37 86%);
    background-color: #222b37;
    // border: 1px solid #222b37;
    backdrop-filter: blur(11px);
    position: absolute;
    right: unset !important;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    @media screen and (max-width:1599px) {
        width: 25vw;
    }

    @media screen and (max-width:1199px) {
        width: 30vw;
    }

    // @media screen and (max-width:1599px) {
    //     min-width: 280px;
    //     max-width: 280px;
    // }

    &.popup {
        left: 50% !important;
    }

    &.popup-left {
        left: 50px !important;
        /* Adjust the value as needed */
    }

    &.popup-right {
        left: -50px !important;
        /* Adjust the value as needed */
    }

    a {
        overflow: hidden;
        display: block;
    }

    .card-image-content {
        position: relative;
        overflow: hidden;
        outline: none !important;

        // &::after {
        //     content: '';
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     background-image: linear-gradient(180deg, transparent, #222b37 100%);
        //     z-index: 1;
        // }

        .cardImg {
            height: 100%;
            border-radius: 10px 10px 0 0;
            min-height: 238px;

            @media screen and (max-width: 1699px) {
                min-height: 208px;
            }

            @media screen and (max-width: 1399px) {
                min-height: 196px;
            }

            @media screen and (max-width: 1199px) {
                min-height: 200px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }
    }

    .card-text-wrapper {
        padding: 20px;

        @media screen and (max-width: 1199px) {
            padding: 10px;
        }
    }

    .btn-wrapper {
        position: relative;
        // margin-bottom: 20px;
        z-index: 2;

        @media screen and (max-width: 1199px) {
            padding: 10px;
        }

        .cartBtn {
            width: 45px;
            height: 45px;
            background-color: transparent;
            border: 1px solid $white;
            font-size: 0 !important;
            font-weight: normal !important;
            line-height: 0 !important;

            img {
                width: 100%;
                max-width: 25px;
                min-width: 25px;
            }
        }

        .previewBtn {
            // background: rgba(255, 255, 255, 0.1490196078);
            color: #fff;
            font-weight: 100;
            min-width: 140px;
            padding: 5px;
            border: 1px solid $white;
            display: flex;

            @media screen and (max-width:1799px) {
                min-width: 100px;
                padding: 10px 8px;
            }

            img {
                width: 100%;
                max-width: 25px;
                min-width: 25px;
            }
        }

        .shareBtn {
            color: #fff;
            font-weight: 100;
            padding: 5px 20px;
            border: 1px solid $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            line-height: 20px;
        }

        .cartBtn,
        .previewBtn,
        .favouriteBtn,
        .shareBtn {
            transform: scale(1) !important;
            border-radius: 6px;
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }
        }

        .favouriteBtn {
            // background: rgba(255, 255, 255, 0.1490196078);
            min-width: 45px;
            width: 45px;
            height: 45px;
            color: #fff;
            font-weight: 100;
            border: 1px solid $white;
            font-size: 0 !important;
            font-weight: normal !important;
            line-height: 0 !important;

            img {
                width: 100%;
                max-width: 25px;
                min-width: 25px;
            }
        }
    }

    .card-text-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 16px;
        margin-bottom: 20px;

        span {
            font-size: 24px;
            display: flex;
            align-items: center;
            color: $primary-color;

            @media screen and (max-width:1799px) {
                font-size: 20px;
            }

            i {
                font-size: 16px;
                text-decoration: line-through;
                opacity: 0.6;
                margin-left: 8px;
                font-style: normal;
                color: $white;

                @media screen and (max-width:1799px) {
                    font-size: 14px;
                    margin-right: 4px;
                }

                &.total-downloads {
                    text-decoration: none;
                    margin-left: auto;
                }
            }
        }
    }

    .tag-wrapper {
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        flex-wrap: wrap;

        .tag {
            background: rgba(255, 255, 255, 0.1490196078);
            color: $white;
            font-size: 10px;

            padding: 0 10px;
            border-radius: 30px;
        }
    }

    .Title {
        h2 {
            font-weight: 500;
            font-size: 20px;
            color: $white;
            line-height: 26px;

            @media screen and (max-width:1599px) {
                font-size: 18px;
            }
        }
    }

    .discripion {
        margin-bottom: 20px;

        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.6);

            @media screen and (max-width:1599px) {
                -webkit-line-clamp: 3;
                line-clamp: 3;
            }
        }
    }
}

// .css-advny6-MuiPopper-root {
//     z-index: 1000;
//     border-radius: 6px;
//     background-color: #222b37;
//     padding: 10px;
//     box-shadow: 0 0 13px 2px #00000080;
//     margin-top: 10px !important;

.share-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 6px;
    margin-top: 10px;

    button {
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;

        img {
            width: 100%;
            max-width: 38.75px;
        }
    }
}
.inFavourite {
    background: #FFB43A;
    border-color: #FFB43A !important;
    opacity: 1 !important;
}
// }

@media screen and (max-width:1024px) {
    #popup-root {
        display: none;
    }
}

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.popup-content {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9);
}