.custom-loader {
  display: flex;
  gap: 0.3rem;

  .box {
    display: none;
    background-color: #fff5;
    width: 0.4rem;
    height: 2rem;
    border-radius: 0.25rem;
    animation: pulse 1.2s ease-in-out infinite;
    transform: scaleY(0.8);
  }
  .box:nth-child(2) {
    animation-delay: 0.2s;
  }
  .box:nth-child(3) {
    animation-delay: 0.4s;
  }
  .box:nth-child(4) {
    animation-delay: 0.6s;
  }
}

@keyframes pulse {
  0% {
    transform: scaleY(0.8);
    background-color: #fff5;
  }

  30% {
    transform: scaleY(1.1);
    background-color: #fff8;
  }

  60% {
    transform: scaleY(0.8);
    background-color: #fff5;
  }
}