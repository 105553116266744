@import '../../../assets/scss/abstracts/variables';

header {
  transition: 0.3s all;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  min-height: 100px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 0;
    // background: $gradient-color;
    left: 50%;
    transform: translatex(-50%);
    transition: 0.5s all;
  }

  &.hide {
    top: -6em;
  }

  &.show {
    top: 0;
    position: fixed;
  }

  .container {
    max-width: 100%;
    padding: 0 60px;

    @media screen and (max-width: 1260px) {
      // padding: 0 30px;
    }

    @media screen and (max-width: 1024px) {
      padding: 0 12px;
      max-width: 960px;
    }
  }

  &.fixed-menu {
    padding: 10px 0;
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.1);
    width: 100%;
    background: #ecf7ff;
    animation: slide-down 0.7s;

    &:before {
      width: 100%;
    }
  }

  .right_wrapper {
    width: 100%;
    max-width: calc(100% - 300px);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 1199px) {
      max-width: calc(100% - 200px);
    }
  }

  .navbar {
    position: relative;
    padding: 0;
    padding: 15px 0;
    background: rgba(22, 21, 21, 0.8784313725);

    @media screen and (max-width: 575px) {
      padding: 15px 0;
    }

    &.sticky {
      background: rgba(22, 21, 21, 0.8784313725);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      padding: 10px 0;
    }

    .logo {
      display: flex;

      .navbar-brand {
        display: flex;
        padding: 0;
        position: relative;
        margin: 0;

        img {
          max-width: 200px;

          @media (max-width: 767px) {
            max-width: 180px;
          }

          @media (max-width: 480px) {
            max-width: 110px;
          }
        }
      }
    }

    .navbar-toggler {
      align-self: center;
      width: 50px;
      height: 50px;
      padding: 0;
      margin: 0;
      outline: none;
      position: relative;
      border: none;
      cursor: pointer;
      -webkit-appearence: none;
      -webkit-tap-highlight-color: transparent;
      margin-left: auto;
      margin-right: 0;

      @media (max-width: 1199px) {
        display: block;
      }

      &:focus {
        box-shadow: none;
      }

      svg {
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        display: block;

        path {
          transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s),
            stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
          stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
          stroke-dashoffset: var(--offset, 126px);
          transform: translateZ(0);
          stroke: $white;

          &:nth-child(1) {
            --array-2: 90px;
          }

          &:nth-child(2) {
            --duration: 0.7s;
            --easing: ease-in;
            stroke: $white;
          }

          &:nth-child(3) {
            --offset: 133px;
            --array-2: 95px;
            stroke: $white;
          }
        }
      }
    }

    .offcanvas {
      width: 100%;
      z-index: 9991;

      @media (max-width: 1199px) {
        background: linear-gradient(124deg, #0c0a34 0%, rgb(3, 3, 3) 50%, #0c0a34 100%);
        max-width: 450px;
        // height: calc(100vh - 90px);
      }

      .offcanvas-header {
        padding-bottom: 0;

        .btn-close {
          outline: none;
          box-shadow: none;
          filter: invert(1);
          opacity: 1;
        }
      }

      .offcanvas-body {
        justify-content: flex-end;

        .navbar-nav {
          position: relative;
          align-items: center;

          @media (max-width: 1199px) {
            padding: 20px 0;
            align-items: flex-start;
            height: 100vh;
          }

          .nav-item {
            transition: 0.3s all;
            margin: 0;
            line-height: 0;
            margin-right: 40px;

            @media (max-width: 1399px) {
              margin-right: 30px;
            }

            @media (max-width: 1199px) {
              margin-right: 0px;
            }

            .nav-link {
              padding: 0 0px;
              font-size: 16px;
              line-height: 22px;
              color: $white;
              transition: 0.3s all;
              position: relative;
              font-weight: 400;
              font-family: $Inter-Medium;
              text-transform: uppercase;
              letter-spacing: 0.05em;

              @media (max-width: 1199px) {
                padding: 15px 0;
                color: $white;
              }

              &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0px;
                width: 15px;
                height: 1px;
                background: $primary-color;
                opacity: 0;
                transition: 0.3s all;
                z-index: -1;

                @media screen and (max-width: 1199px) {
                  left: 0;
                  margin: auto;
                  bottom: 5px;
                }
              }

              &.active,
              &:hover {
                color: $primary-color;
                opacity: 1;

                &:before {
                  opacity: 1;
                }
              }
            }

            &.has-megamenu {
              position: static;

              .nav-link {
                display: flex;
                align-items: center;

                svg {
                  @media (max-width: 1199px) {
                    margin-left: 5px;

                    path {
                      stroke: $white;
                    }
                  }
                }

                &:hover {
                  svg {
                    @media (max-width: 1199px) {
                      path {
                        stroke: $primary-color;
                      }
                    }
                  }
                }

                &::after {
                  display: none;
                }
              }

              .dropdown-menu {
                line-height: 1.3;
                left: 50%;
                transform: translateX(-50%);

                &.megamenu {
                  width: 730px;
                  margin-top: 30px;
                  background: $black;
                  border-radius: 12px;
                  border: none;
                  padding: 0;

                  // overflow: hidden;
                  @media (min-width: 1200px) {
                    .category_modal {
                      position: relative;
                      overflow: hidden;
                      padding: 50px 30px;
                      border-radius: 12px;

                      &::after {
                        content: '';
                        position: absolute;
                        background: #ffffff;
                        filter: blur(90px);
                        height: 125px;
                        width: 125px;
                        border-radius: 50%;
                        left: -75px;
                        bottom: 100px;
                      }

                      &:before {
                        content: '';
                        position: absolute;
                        background: rgba(255, 180, 58, 0.7);
                        filter: blur(90px);
                        width: 250px;
                        height: 250px;
                        right: -160px;
                        bottom: -115px;
                        border-radius: 50%;
                      }
                    }
                  }

                  @media (max-width: 1199px) {
                    border: none;
                    padding: 0;
                    margin: 0;
                    background: transparent;
                    width: auto;
                    padding-left: 15px;
                    transform: none;
                  }

                  &::after {
                    content: '';
                    display: inline-block;
                    margin: 0 5px;
                    vertical-align: middle;
                    position: absolute;
                    top: -10px;
                    left: 100px;
                    border-bottom: solid 10px rgb(0 0 0);
                    border-left: solid 10px transparent;
                    border-right: solid 10px transparent;

                    @media (max-width: 1199px) {
                      display: none;
                    }
                  }

                  .top_modal {
                    // padding-bottom: 30px;
                    // margin-bottom: 30px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                    @media (max-width: 1199px) {
                      padding-bottom: 0;
                      margin-bottom: 0;
                      border-bottom: none;
                    }
                  }

                  .col_megamenu {
                    .prototypes {
                      li {
                        align-items: center;

                        a {
                          font-family: $Inter-SemiBold;
                          font-size: 16px;
                          color: $white;
                          transition: all 0.3s;

                          @media (max-width: 1199px) {
                            color: $secondary-color;
                            font-family: $Inter-Medium;
                            font-size: 14px;

                            &:hover {
                              color: $primary-color;
                            }
                          }
                        }
                      }
                    }

                    .tech_temp {
                      display: flex;
                      flex-wrap: wrap;
                      gap: 10px 0;

                      @media (max-width: 1199px) {
                        display: block;
                        flex-wrap: nowrap;
                      }
                    }

                    li {
                      display: flex;
                      align-items: flex-start;
                      padding: 10px;
                      margin-bottom: 10px;
                      transition: all 0.3s;
                      flex: 0 0 50%;

                      @media (max-width: 1199px) {
                        text-align: center;
                        padding: 10px 0;
                        flex: 0 0 100%;
                        margin-bottom: 0;
                      }

                      &:hover {
                        background: #1c1c1c;
                        border-radius: 9px;

                        @media (max-width: 1199px) {
                          background: none;
                        }
                      }

                      .tech_img {
                        position: relative;
                        flex: 0;
                        margin-right: 10px;

                        &.ht {
                          &:after {
                            background-color: rgba(241, 101, 41, 0.5);
                          }
                        }

                        &.woo {
                          &:after {
                            background-color: #9b5c8f;
                          }
                        }

                        &.wp {
                          &:after {
                            background-color: #0073aa;
                          }
                        }

                        &.sp {
                          &:after {
                            background-color: #98cc29;
                          }
                        }

                        &:after {
                          content: '';
                          position: absolute;
                          height: 40px;
                          width: 40px;
                          top: 12px;
                          left: 4px;
                          filter: blur(10px);
                          border-radius: 9px;

                          @media (max-width: 1199px) {
                            display: none;
                          }
                        }

                        img {
                          min-width: 48px;
                          height: 48px;
                          width: 48px;
                          border-radius: 10px;
                          position: relative;
                          z-index: 1;

                          @media (max-width: 1199px) {
                            display: none;
                          }
                        }
                      }

                      .head_title {
                        a {
                          font-size: 16px;
                          font-family: $Inter-SemiBold;
                          color: #ffffff;
                          margin-bottom: 5px;
                          transition: all 0.3s;
                          display: inline-block;

                          @media (max-width: 1199px) {
                            color: $white;
                            opacity: 0.7;
                            font-family: $Inter-Medium;
                            font-size: 14px;

                            &:hover {
                              color: $primary-color;
                              opacity: 1;
                            }
                          }
                        }

                        p {
                          font-size: 12px;
                          text-transform: capitalize;
                          color: #ffffff;
                          opacity: 0.5;
                          line-height: 18px;

                          @media (max-width: 1199px) {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .buttonBlock {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      border-left: none !important;
    }

    .btn_wrapper {
      padding: 11px 24px;
      min-width: 130px;
      margin-left: 15px;

      &:last-child {
        margin-right: 0;
      }

      * {
        font-size: 14px;
        line-height: 18px;
        font-family: $Inter-Medium;
      }
    }

    .cart_wrapper {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;

      & .notification {
        border-radius: 50rem;
        background-color: #fe385c;
        right: 0px;
        top: 5px;
        position: absolute;
        font-size: 0.6rem;
        margin-top: -0.1rem;
        margin-left: -0.5rem;
        padding: 0.2em 0.45em;
        display: inline-block;
        color: #fff;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
      }

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
        margin: 0;

        & .notification {
          top: 0px;
          right: -3px;
        }
      }
    }

    .profile_nav {
      margin-right: 10px;
      min-width: 20px;

      .nav-item {
        .dropdown-toggle::after {
          display: none;
        }

        .dropdown-toggle.show {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          @media (max-width: 480px) {
            border-radius: 0 !important;
          }

          svg {
            transform: rotate(180deg);
          }
        }

        .dropdown-menu {
          min-width: 100%;
          margin-top: 0;
          border: none;
          border-top: 1px solid #444755;
          padding: 0;
          border-radius: 0;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          @media (min-width: 481px) {
            overflow: hidden;
          }

          @media (max-width: 480px) {
            border-radius: 0 !important;
            margin-top: 10px;
            min-width: 130px;
            left: auto;
            right: 0;

            &:after {
              content: '';
              display: inline-block;
              margin: 0 5px;
              vertical-align: middle;
              position: absolute;
              top: -7px;
              right: 0;
              border-bottom: solid 7px rgb(255, 255, 255);
              border-left: solid 7px transparent;
              border-right: solid 7px transparent;
            }
          }

          li {
            &:last-child {

              a,
              button {
                border-bottom: none;
              }
            }

            a,
            button {
              padding: 8px 12px;
              font-size: 12px;
              font-family: $Inter-Medium;
              color: black;
              border-bottom: 1px solid #1414140a;

              &:hover {
                background: #f6f6f6;
              }
            }
          }
        }

        .nav-link {
          display: flex;
          align-items: center;
          background-color: $white;
          font-family: $Inter-Medium;
          color: $secondary-color;
          font-size: 14px;
          font-weight: 500;
          padding: 8px 12px;

          @media (max-width: 480px) {
            padding: 0;
          }

          img {
            margin-right: 5px;

            @media (max-width: 480px) {
              margin-right: 0;
            }
          }

          span {
            display: inline-block;
            margin-right: 10px;

            @media (max-width: 480px) {
              display: none;
            }
          }

          svg {
            @media (max-width: 480px) {
              display: none;
            }
          }
        }
      }
    }

    .mobile-btn {
      .btn_wrapper {
        font-size: 13px;
        padding: 0;
        min-width: 80px;
        border-radius: 0.25rem;
      }
    }
  }

  .modal-backdrop {
    &.show {
      background: $secondary-color;
      opacity: 0.8;

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  @keyframes smoothScroll {
    0% {
      transform: translateY(-142px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}

.timer {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  @media (min-width: 575px) and (max-width: 767px) {
    font-size: 15px;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    justify-content: center;
  }
}

.timer-count {
  display: flex;
  padding: 4.5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  border-radius: 8px;
  background: #af6d00;
  min-width: 46px;

  @media (max-width: 374px) {
    min-width: auto;
  }
}

.timer-colon {
  color: #fff;
  font-size: 30px;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sale-50 {
  flex-shrink: 0;
  border-radius: 117px;
  border: 1px solid rgba(216, 0, 0, 0.12);
  background: #d80000;
  padding: 2px 10px;
  color: white;
}

.hide-mob {
  display: block;

  @media (max-width: 995px) {
    display: none;
  }
}

.hide-sm-mob {
  display: block;

  @media (max-width: 575px) {
    display: none;
  }
}

.search-btn {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  border-radius: 0 5px 5px 0;
  line-height: 0;
}

.input-search {
  max-width: 250px;
  line-height: 20px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgba(14, 17, 51, .1);
  font-size: 16px;
  padding: 15px 50px 15px 20px;
  width: 100%;
  font-family: 'Inter-Medium';
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
  backdrop-filter: blur(14px);
  color: #fff;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

::placeholder {
  color: #fff;
}

.search-cross {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  border-radius: 0 5px 5px 0;
  line-height: 0;
  font-size: 22px;
  font-weight: bolder;
  cursor: pointer;
}

.search-card {
  position: absolute;
  padding: 0 15px;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
  width: 100%;
  margin-top: 1.5px;
  max-height: 210px;
  overflow: auto;
  border-radius: 2px;

  h4 {
    color: #000;
    font-size: 18px;
    padding: 15px 0;
  }

  ul {
    li {

      margin: 10px 0;

      a {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        color: #000;
        line-height: 1.5;
        transition: all 0.3s;

        &:hover {
          color: #fFB43A;
        }

        span {
          display: block;
          width: 35px;
          height: 35px;
          text-align: center;
          margin-right: 10px;
          display: flex;
          align-items: center;

          img {
            max-width: 35px;
            max-height: 35px;
            object-fit: cover;
          }
        }
      }

    }
  }
}