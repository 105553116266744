.confirmation-modal-wrapper{
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000061;
    .blankClose{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    .confirmation-modal{
        background: #f9b328;
        padding: 30px 20px;
        border-radius: 12px;
        text-align: center;
        max-width: 440px;
        position: relative;
        z-index: 2;
        h2 {
            font-size: 22px;
            margin-bottom: 22px;
        }
        p {
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 20px;
            margin-top: -10px;
        }
        .btnGroup{
            gap: 10px;
            button {
                width: 100%;
                background: #0000006e;
                color: #fff;
                padding: 8px 10px;
                font-size: 16px;
                text-transform: uppercase;
                border-radius: 6px;
                transition:background 0.5s ease ;
                &:hover{
                    background: #000;
                }
                &.onCancel{
                    background: #000;
                }
            }
        }
    }
    &.blink{
        animation: blink 1s;
    }
}
@keyframes blink {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.05);
    }
    100%{
        transform: scale(1);
    }
}


/* Placeholder js folder css start */
.swiper-slide .react-loading-skeleton {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    aspect-ratio: 1/1.5;
    overflow: hidden;
    background: linear-gradient(to right, #f5f5f4 8%, #E7E6E6 18%, #f5f5f4 33%) !important;
    border-radius: 8px;
  
    &::after {
      background-image: linear-gradient(to right, #ffffff 8%, #ffffff 18%, #f5f0f0 33%) !important;
    }
} 
// Product Category Loading Skeleton
.productCategory .react-loading-skeleton {
    width: 100%;
    aspect-ratio: 1/1.5;
    border-radius: 8px;
  
    @media (max-width: 767px) {
      max-width: 300px;
      display: block;
      margin: 0 auto;
    }
}
// Product Details Loading Skeleton
.product_details {
    .react-loading-skeleton {
      width: 100%;
  
      &.w_1fourth {
        max-width: 25%;
      }
  
      &.w_half {
        max-width: 50%;
      }
  
      &.w_1third {
        max-width: 75%;
      }
  
      &.circleSkel1 {
        width: 50px;
      }
  
      &.circleSkel2 {
        width: 40px;
      }
  
      &.btn-skeleton {
        @media (max-width: 575px) {
          width: 100px !important;
        }
      }
    }
  
    .ItemDiscription {
      &.ph {
        column-gap: 40px;
  
        div {
          margin: 0;
        }
      }
    }
  
  
    .btnBlocks {
      gap: 10px;
      flex-wrap: wrap;
    }
}
.react-loading-skeleton {
    background: linear-gradient(to right, #f5f5f4 8%, #E7E6E6 18%, #f5f5f4 33%) !important;
    border-radius: 8px;
  
    &::after {
      background-image: linear-gradient(to right, #d9d9d9 8%, #e5e5e5 18%, #e3e3e2 33%) !important;
    }
  
    &.span-skeleton {
      width: 100px !important;
  
      @media(max-width: 575px) {
        width: 60px !important;
      }
    }
  
    &.p-skeletion {
      &.one {
        max-width: 500px;
  
        @media(max-width: 575px) {
          max-width: 300px;
        }
      }
  
      &.two {
        max-width: 450px;
  
        @media(max-width: 575px) {
          max-width: 250px;
        }
      }
  
      &.three {
        max-width: 400px;
  
        @media(max-width: 575px) {
          max-width: 200px;
        }
      }
  
      &.four {
        max-width: 350px;
  
        @media(max-width: 575px) {
          max-width: 150px;
        }
      }
    }
  
    &.btn-skeleton {
      width: 150px !important;
  
      @media(max-width: 575px) {
        width: 75px !important;
      }
    }
  
    &.skel-rounded {
      border-radius: 12px;
    }
}  
@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
  
    100% {
      background-color: hsl(200, 20%, 95%);
    }
}
@keyframes shimmer {
    100% {
      transform: translateX(120%) skewX(20deg);
    }
}
/* Placeholder js folder css end's */
.widget-visible{
  
    animation: bounceIn1 4s infinite alternate;
  
}

@keyframes bounceIn1 {
	0% {
    transform: translateY(0);
  }
	50% {
    transform: translateY(-15px);
  }
  100%{
    transform: translateY(0);
  }
}