/* Popup.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.popup_newletter {
  box-shadow: none;
  width: 100%;
  max-width: 1100px;
  position: relative;
  background: #ffb43a;
  padding: 35px 60px 0;
  border-radius: 12px;
  background-image: url('../../assets/images/popup_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 9999999;
}
.popup-overlay:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 999;
  backdrop-filter: blur(20px);
}
body.newletter_popup header {
  z-index: 1 !important;
}
.popup-inner > h2 {
  font-family: clashdisplay-semibold;
  text-align: center;
  font-size: 42px;
  color: rgba(45, 45, 45, 1);
  font-weight: 700;
  max-width: 520px;
  margin: 0 auto;
  text-transform: capitalize;
  padding-top: 35px;
  padding-bottom: 20px;
}
.popup-inner img {
  width: 100%;
  max-width: 190px;
  margin: 0 auto;
  display: flex;
}
.popup-inner a.btn_wrapper {
  background: rgba(45, 45, 45, 1);
  max-width: 220px;
  margin: 0 auto;
  border: 1px solid rgba(45, 45, 45, 1);
}
.popup-inner .popup_templete_box img {
  width: 100%;
  max-width: 100%;
  padding-top: 30px;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.popup_newletter .popup-inner img {
  border-radius: 6px;
}
body.newletter_popup {
  overflow: hidden;
}
.popup_newletter button.close-btn {
  color: #0b0b0b;
  font-family: 'Inter-Regular';
  text-align: center;
  display: flex;
  justify-content: end;
  width: 100%;
  padding-top: 0;
  text-decoration: underline;
  font-size: 13px;
  background-color: rgb(255 255 255 / 25%);
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
/* Newletter sticky */
.newletter_sticky {
  position: fixed;
  background-color: rgba(20, 20, 20);
  width: 100%;
  height: auto;
  top: auto;
  bottom: 0;
  z-index: 9;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.newletter_inner_sticky {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newletter_inner_sticky a.btn_wrapper {
  background: rgba(255, 180, 58, 1);
  color: rgba(20, 20, 20, 1);
  min-width: 131px;
  font-size: 14px;
  padding: 7px 12px;
}
.newletter_inner_sticky h2 {
  font-family: 'Inter-SemiBold';
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  text-transform: capitalize;
}
.popup-overlay > div {
  margin: 0 15px;
}
.footer_sticky_left {
  display: flex;
  align-items: center;
}
.footer_sticky_left img {
  margin-right: 15px;
}
@media screen and (max-width: 991px) {
  .popup_newletter button.close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 5px;
    right: 5px;
  }
  .popup_newletter button.close-btn {
    padding-top: 0;
    font-size: 13px;
  }
  .newletter_sticky {
    display: none;
  }
  .popup_newletter {
    padding: 35px 15px 0;
  }
  .popup-inner > h2 {
    font-size: 28px;
    padding-top: 20px;
    padding-bottom: 15px;
    max-width: 310px;
  }
  .popup-inner img {
    max-width: 145px;
  }
  .popup-inner .popup_templete_box img {
    padding-top: 20px;
  }
  .popup-inner a.btn_wrapper {
    padding: 10px;
    font-size: 14px;
    max-width: 160px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .popup-inner > h2 {
    font-size: 36px;
    padding-top: 25px;
    padding-bottom: 20px;
  }
}
