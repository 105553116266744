@import "../../assets/scss/abstracts/variables";

.wrapper-404 {
    position: relative;
    padding: 125px 0 50px;
    background-color: #1C1C1C;
    min-height: calc(100vh - 66px);
    display: flex;
    align-items: center;
    @media screen and (max-width: 575px) {
        min-height: calc(100vh - 92px);
    }
    .img-wrapper {
        img {
            display: block;
            margin: 0 auto;

            @media screen and (max-width: 991px) {
                margin: 0 auto 20px;
            }
        }
    }

    .info-wrapper {
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);

        @media screen and (max-width: 991px) {
            position: static;
            transform: none;
        }

        h5 {
            text-transform: capitalize;
            font-size: 40px;
            line-height: 1.5;
            font-family: $poppins-semi-bold;

            @media screen and (max-width: 991px) {
                font-size: 32px;
            }

            @media screen and (max-width: 575px) {
                font-size: 22px;
            }
        }

        p {
            font-size: 16px;
            line-height: 1.4;
            max-width: 525px;
            margin-bottom: 20px;

            @media screen and (max-width: 991px) {
                margin: 0 auto 20px;
            }

            @media screen and (max-width: 575px) {
                font-size: 14px;
            }
        }

        a {
            display: inline-block;
            text-transform: unset;
        }
    }
}

.copyRight-wrapper {
    padding: 20px 0;
    position: relative;
    text-align: center;

    // @media screen and (max-width: 575px) {
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    // }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: #D9D9D9;
        opacity: 0.1;
    }

    a {
        color: $white;
        opacity: 0.5;
        transition: all 0.5s ease;

        &:hover {
            color: $primary-color;
            opacity: 1;
        }
    }
}