$primary-color: #FFB43A;
$secondary-color: #1C1C1C;

$text-color: #787694;
$white: #fff;
$black: #000;

/* CLASDISPLAY FONTS */
$clashdisplay-bold : 'clashdisplay-bold';
$clashdisplay-light : 'clashdisplay-light';
$clashdisplay-medium : 'clashdisplay-medium';
$clashdisplay-regular : 'clashdisplay-regular';
$clashdisplay-semibold : 'clashdisplay-semibold';

/* INTER FONTS */
$Inter-Black : 'Inter-Black';
$Inter-Bold : 'Inter-Bold';
$Inter-ExtraBold : 'Inter-ExtraBold';
$Inter-ExtraLight : 'Inter-ExtraLight';
$Inter-Medium : 'Inter-Medium';
$Inter-Regular : 'Inter-Regular';
$Inter-SemiBold : 'Inter-SemiBold';

/* POPPINS FONTS */
$poppins-black : 'poppins-black';
$poppins-bold : 'poppins-bold';
$poppins-medium : 'poppins-medium';
$poppins-regular : 'poppins-regular';
$poppins-semi-bold : 'poppins-semi-bold';
$poppins-thin : 'poppins-thin';