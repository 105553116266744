.btn {
  &:hover {
    background-color: #ffc107;
  }
}

.navbar-light .navbar-brand {
  @media (max-width: 991px) {
    font-size: 14px;
  }
}

.responsive {
  button {
    margin: 0 5px;

    img {
      width: 20px;
    }
  }
}

iframe {
  height: 100vh;
  padding-top: 50px;
  display: block;
  transition: all 0.5s ease-in-out;

  &.mobile {
    max-width: 350px;
    margin: 0 auto;
  }
}

.btn-outline-warning {
  @media (max-width: 991px) {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
}

.liveNav {
  transition: all 0.5s ease-in-out;

  &.hideLiveNav {
    top: -50px;
  }
}

.viewNav {
  position: absolute;
  top: -50px;
  right: 50px;
  transition: all 0.5s ease-in-out;

  &.show {
    top: 50px;
  }
}

#myIframe{
  body{
    &::-webkit-scrollbar {
      width: 1px !important;
      background: transparent !important;
    }
  }
 
}

iframe html body{
  scrollbar-width: thin;
  scrollbar-color: red;
}

iframe html body::-webkit-scrollbar {
  width: 5px;
  // display: none;
}

iframe html body::-webkit-scrollbar-track {
  width: 5px;
}

iframe html body::-webkit-scrollbar-thumb {
  background-color: red;
}
.LivePreView_btn button.btn {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}