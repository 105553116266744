@import '../../assets/scss/abstracts/variables';
.add_collection .modal-content .modal-body .prev_collection .collect_card .card_box {
  align-items: center !important;
}
.Toastify {
  position: relative;
  z-index: 99999 !important;
}
.Toastify__toast-container {
  z-index: 99999 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #f9b328 !important;
}
.product_details {
  margin-bottom: 60px;
  padding: 180px 0 0;
  // overflow: hidden;
  position: relative;

  @media screen and (max-width: 1399px) {
    // padding: 250px 0;
  }

  @media screen and (max-width: 767px) {
    padding: 140px 0 0px;
    margin-bottom: 20px;
  }

  // &::after {
  //     content: "";
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     z-index: 1;
  //     background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%), linear-gradient(180deg, rgba(20, 20, 20, 0.6) 0%, rgba(20, 20, 20, 0.11) 21.88%, rgba(20, 20, 20, 0) 100%), linear-gradient(271deg, rgba(20, 20, 20, 0.09) 0.81%, rgba(20, 20, 20, 0.72) 68.38%, rgba(20, 20, 20, 0.846) 99.09%);
  //     left: 0;
  //     top: 0;
  //     z-index: 2;
  // }

  div {
    position: relative;
    z-index: 3;
  }

  .banner_bkg {
    position: sticky;
    line-height: 0;
    left: 0;
    top: 30px;
    width: 100%;
    z-index: 1;

    @media screen and (max-width: 991px) {
      margin-top: 40px;
      position: relative;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }

    &::after {
      // content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        271deg,
        rgba(20, 20, 20, 0) 34.11%,
        rgba(20, 20, 20, 0.8) 78.79%,
        #141414 99.09%
      );
      opacity: 0.6;
    }
  }

  .ItemDiscription {
    margin-bottom: 20px;

    @media (max-width: 375px) {
      flex-wrap: wrap;
    }

    > div {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      img {
        margin-right: 5px;
      }
    }
  }

  .heading {
    p {
      opacity: 1;
      margin-bottom: 40px;
      max-width: 600px;
      text-align: justify;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .support-group {
    margin-bottom: 30px;

    ul {
      margin-bottom: 30px;

      li {
        padding-left: 30px;
        padding-bottom: 0;
        align-items: center;
        margin-bottom: 0;

        &::after {
          content: none;
        }

        &::before {
          background-image: none;
          width: 16px;
          height: 8px;
          border: 2px solid $white;
          border-right: none;
          border-top: none;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }

    .form-check {
      input {
        border-radius: 0;
        background-color: #c3c3c3;
        border: 1px solid #ffff;

        &:focus {
          box-shadow: none;
        }

        &:checked {
          background-color: $primary-color;
          border: 1px solid $primary-color;
        }
      }
    }
  }

  .btnBlock {
    margin-bottom: 22px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 992px) and (max-width: 1399px) {
      display: block;
    }

    @media screen and (max-width: 767px) {
      display: block;
    }

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .btn_group {
      display: flex;
      align-items: center;
      margin-right: 15px;

      @media screen and (min-width: 992px) and (max-width: 1399px) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 480px) {
        display: block;
      }
    }

    .btn_wrapper {
      margin-right: 14px;

      @media screen and (max-width: 480px) {
        margin-right: 0;
        margin-bottom: 15px;
        min-width: 100%;

        &.light {
          // min-width: calc(100% - 70px);
          min-width: 100%;
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .share_group {
      .img {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .share_group.prod-details {
      display: flex;
      align-items: center;
    }
  }

  .product_slider {
    margin-top: 35px;
    margin-bottom: 50px;

    .images_wrapper {
      border-radius: 9px;
      overflow: hidden;
      font-size: 0;
      line-height: 0;
      width: 100%;

      img {
        border-radius: 9px;
        overflow: hidden;
      }
    }
  }

  .heading {
    h2 {
      font-size: 34px;
      line-height: 1.2;

      @media (max-width: 1199px) {
        font-size: 30px;
      }

      @media (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  .heading {
    h1 {
      font-size: 34px;
      line-height: 1.2;

      @media (max-width: 1199px) {
        font-size: 30px;
      }

      @media (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  .shareSection {
    display: flex;
    align-items: center;
    column-gap: 6px;
    margin-bottom: 20px;

    @media (max-width: 575px) {
      column-gap: 4px;
    }

    span {
      @media (max-width: 575px) {
        font-size: 13px;
      }
    }

    button {
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;

      img {
        width: 100%;
        max-width: 38.75px;
      }
    }
  }
}

.productDetails_wrapper {
  .MuiTabPanel-root {
    padding: 40px 0 0;

    @media screen and (max-width: 1199px) {
      padding-top: 20px;
    }
  }

  .MuiBox-root {
    min-height: auto;
    border: 0;
  }

  .MuiTab-root {
    padding: 0;
    font-family: $Inter-Medium;
    font-size: 20px;
    line-height: 24px;
    color: $white;
    opacity: 0.5;
    position: relative;
    min-width: auto;
    letter-spacing: normal;
    min-height: auto;
    border-right: 1px solid #d9d9d9;
    margin-right: 40px;
    padding-right: 40px;
    text-transform: none !important;

    @media screen and (max-width: 1199px) {
      padding-right: 25px;
      margin-right: 25px;
      font-size: 18px;
    }

    @media screen and (max-width: 575px) {
      font-size: 16px;
    }

    @media screen and (max-width: 480px) {
      padding-right: 20px;
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border: 0;
    }

    &.Mui-selected {
      opacity: 1;
      color: $white;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .desc_details {
    padding-right: 70px;

    @media screen and (max-width: 991px) {
      padding-right: 0;
    }

    .description_list {
      margin-bottom: 50px;

      ul {
        li {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #1c1c1c;
          padding-bottom: 14px;
          margin-bottom: 12px;

          @media (max-width: 480px) {
            display: block;
          }

          &:last-child {
            margin: 0;
            padding: 0;
            border: 0;
          }

          span {
            font-size: 16px;
            line-height: 19px;
            font-family: $Inter-Regular;
          }

          > span {
            min-width: 165px;
            margin-right: 32px;
            display: inline-block;

            @media (max-width: 480px) {
              margin-right: 0;
              margin-bottom: 5px;
            }
          }

          div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            span {
              opacity: 0.5;
            }

            &.labels {
              margin-top: -5px;
            }

            label {
              font-size: 12px;
              line-height: 15px;
              font-family: $Inter-Regular;
              padding: 6px 14px;
              background: #282828;
              border-radius: 25px;
              margin-right: 5px;
              margin-top: 5px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .text_Wrapper {
      h2 {
        font-family: $Inter-SemiBold;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $white;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      div {
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        font-family: $Inter-Regular;

        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        padding-left: 15px;
        margin-bottom: 30px;
        opacity: 0.75;

        &:last-child {
          margin-bottom: 0;
        }

        li {
          opacity: 0.5;
          list-style: disc;
          font-size: 16px;
          line-height: 19px;
          font-family: $Inter-Regular;
          margin-bottom: 10px;
          text-transform: capitalize;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.tabType {
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;

          li {
            list-style: none;
            font-size: 12px;
            line-height: 15px;
            font-family: 'Inter-Regular';
            padding: 6px 14px;
            background: #282828;
            border-radius: 25px;
            margin-right: 5px;
            margin-top: 5px;
            margin-bottom: 0;
            opacity: 1;
          }
        }
      }

      .iconList {
        margin-top: 25px;

        h2 {
          margin-bottom: 15px;
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            position: relative;
            list-style: none;
            padding-left: 25px;
            margin-bottom: 12px;

            &::after {
              content: '';
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              background: url(../../assets/images/icon/right-icon.svg) no-repeat;
              position: absolute;
              left: 0;
            }
          }

          &.falseItem {
            li {
              &::after {
                background: url(../../assets/images/icon/wrong-icon.svg) no-repeat;
              }
            }
          }
        }
      }
    }
  }

  .support_details {
    .text_Wrapper {
      margin-top: 40px;
    }
  }

  .images_wrapper {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(20, 20, 20, 0) 0%,
        rgba(20, 20, 20, 0.87) 65.62%,
        #141414 100%
      );
    }
  }

  .supportBlock {
    .supportLogo {
      width: 108px;
      height: 108px;
    }

    .supoortTitle {
      width: 100%;
      max-width: calc(100% - 108px);
      padding-left: 20px;

      @media (max-width: 575px) {
        max-width: 100%;
        padding-left: 0;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      h2 {
        font-family: $Inter-Regular;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      p {
        font-family: $Inter-Regular;
        opacity: 0.5;
        font-size: 14px;
      }
    }
  }
}

.reviewBlock {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #282828;

  &:last-child {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .starBlock {
    display: flex;

    img {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  p {
    margin: 15px 0 20px;
    font-size: 16px;
    line-height: 26px;
    color: $white;
    opacity: 0.5;

    &:last-child {
      margin: 0;
    }
  }

  h2 {
    font-family: $Inter-Medium;
    font-size: 18px;
    line-height: 26px;
    color: $white;
  }
}

.wizard-container {
  fieldset:not(.active) {
    display: none;
  }
}

.add_collection,
.create_collection {
  .modal-content {
    .modal-header {
      margin-bottom: 30px;

      .btn-close {
        background: transparent url(../../assets/images/icon/close.svg);
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 0;
        height: 24px;
        width: 24px;
      }
    }

    .modal-body {
      padding: 0;

      .review {
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $white;
          opacity: 0.5;
        }
      }

      .no_collection {
        padding: 50px;
        text-align: center;

        @media (max-width: 575px) {
          padding: 30px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $white;
          opacity: 0.4;
        }
      }

      .prev_collection {
        max-height: 280px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          background-color: rgba(0, 0, 0, 0);
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border: 2px solid transparent;
          border-radius: 5px;
          box-shadow: inset 0 0 0 12px rgba(255, 255, 255, 0.37);
        }

        .collect_card {
          .card_box {
            border: 1px solid #363636;
            padding: 10px;
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &.selected,
            &:hover {
              background-color: #282828;
            }

            &.selected {
              border: 1px solid $primary-color;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .card_img {
              margin-right: 10px;
            }

            .card_detail {
              h2 {
                font-size: 16px;
                margin-bottom: 5px;
                font-weight: 600;
              }

              p {
                font-size: 12px;
                line-height: 15px;
                color: #ffffff;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .btn_group {
      display: flex;
      align-items: center;
      margin-top: 30px;
      justify-content: space-evenly;

      @media (max-width: 480px) {
        display: block;
      }

      .btn_wrapper {
        max-width: 100%;
        margin-right: 10px;
        font-size: 14px;

        &:last-child {
          margin-right: 0;
          div {
            color: $white;
          }
        }

        @media (max-width: 480px) {
          width: 100%;
          margin-right: 0;
        }
      }

      .create {
        background-color: #363636;
        padding: 12px 24px;
        border-radius: 0;
        // color: #ffffff;
        font-weight: 300;
        border: 1px solid #363636;
        font-family: $Inter-SemiBold;

        &:hover {
          background-color: transparent;
          border: 1px solid $primary-color;
        }
      }

      .done_btn {
        max-width: 130px;
        min-width: auto;
        background-color: $primary-color;
        color: $white;
        border: 1px solid transparent;

        &:hover {
          background-color: transparent;
          border-color: $primary-color;
          color: $primary-color;
        }

        @media (max-width: 480px) {
          max-width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}

.add_collection {
  .create {
    @media (max-width: 480px) {
      padding: 12px;
    }
  }
}

.create_collection {
  .modal-content {
    .modal-body {
      .btn_group {
        .create {
          label {
            background-color: #ffb43a !important;
          }
        }

        .done_btn {
          label {
            background-color: #363636;
          }
        }
      }
    }
  }
}
