@import '../../assets/scss/abstracts/variables';
.collection_itemBlock {
  margin-top: 10px;
}
.top_section {
  border-bottom: 1px solid #232323;
  background: #1c1c1c;
  margin-bottom: 50px;
}

.user_profile {
  padding-top: 180px;
  margin-bottom: 50px;

  @media (max-width: 991px) {
    padding-top: 180px;
  }

  @media (max-width: 575px) {
    margin-bottom: 40px;
  }

  .user_details {
    display: flex;
    align-items: center;

    .user_img {
      align-items: center;
      display: flex;
      height: 148px;
      justify-content: center;
      line-height: 0;
      width: 148px;
      color: #fff;
      background-color: rgb(67 67 67);
      border-radius: 100%;

      @media (max-width: 991px) {
        width: 100px;
        height: 100px;
      }

      @media (max-width: 480px) {
        width: 80px;
        height: 80px;
      }
      > div {
        width: 100%;
        height: 100%;
        font-size: 62px;
        background: transparent;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        @media (max-width: 991px) {
          font-size: 42px;
        }

        @media (max-width: 480px) {
          font-size: 32px;
        }
      }
    }

    .user_name {
      margin-left: 20px;

      h2 {
        font-size: 34px;
        color: $white;
        margin-bottom: 5px;

        @media (max-width: 1199px) {
          font-size: 30px;
        }

        @media (max-width: 991px) {
          font-size: 24px;
        }

        @media (max-width: 480px) {
          font-size: 20px;
        }
      }

      p {
        font-size: 16px;
        line-height: 26px;
        color: $white;
        opacity: 0.5;
      }
    }
  }
}

.tab_link {
  padding-left: 30px;

  @media (max-width: 991px) {
    padding-left: 0;
  }

  ul {
    display: inline-flex;

    @media (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      gap: 0px 30px;
    }

    li {
      padding-right: 30px;

      @media (max-width: 767px) {
        padding: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        color: $white;
        opacity: 0.5;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 15px;
        display: inline-block;
        position: relative;

        @media (max-width: 525px) {
          // width: 100%;
        }

        &.active {
          opacity: 1;
          border-bottom: 1px solid #ffb43a;

          &::after {
            content: '';
            position: absolute;
            background: linear-gradient(
              180deg,
              rgba(255, 180, 58, 0) 0%,
              rgba(255, 180, 58, 0.1) 100%
            );
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.btn {
  background-color: transparent;
  border-radius: 0;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    box-shadow: none;
    background-color: transparent;
  }
}

.css-b62m3t-container {
  width: 165px;

  @media (max-width: 480px) {
    width: 135px;
  }
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  background-color: #0b0b0b !important;
  border: 1px solid #1e1e1e !important;
  height: 45px !important;
  box-shadow: none !important;
  border-radius: 0px !important;

  .css-tj5bde-Svg {
    fill: #ffffff4d;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-26l3qy-menu {
    background-color: #000;
  }
}

div[id='react-select-*'] {
  margin: 0 !important;
  background-color: #000000b5;
  padding: 2px !important;
  border-radius: 5px !important;

  * {
    padding-top: 0;
    padding-bottom: 0;
  }

  > div {
    min-height: 400px;
  }
}

.inner_wrapper {
  .page_title {
    h2 {
      font-family: $clashdisplay-bold;
      margin-bottom: 15px;
      font-size: 26px;

      @media (max-width: 991px) {
        font-size: 22px;
      }
    }
  }

  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      display: block;
    }

    .leftside {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    .sort {
      display: flex;
      align-items: center;
      margin-right: 20px;

      @media (max-width: 575px) {
        margin-right: 10px;
      }

      label {
        margin-right: 10px;
        font-size: 16px;

        @media (max-width: 480px) {
          font-size: 14px;
        }
      }
    }

    .view_wrapper {
      border: 1px solid #1e1e1e;
      padding: 10px 0;
      display: flex;

      // @media(max-width: 480px) {
      //     display: none;
      // }

      .boxView.active {
        opacity: 0.3;
      }

      .listView.active {
        opacity: 0.3;
      }

      > div {
        margin: 0 10px;
        line-height: 0;
        cursor: pointer;

        img {
          line-height: 0;
        }
      }
    }

    .rightside {
      .btn_wrapper {
        min-width: 200px;
      }
    }
  }

  .card_block {
    .single_card {
      position: relative;
      cursor: default;

      .card_view {
        border: 1px solid #1e1e1e;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        margin-bottom: 15px;
        transition: all 0.3s;
        background: #181818;
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: #2a2a2a;
        }

        @media (max-width: 430px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      @media (max-width: 991px) {
        display: block;
      }

      // @media(max-width: 480px) {
      //     text-align: center;
      // }

      &:last-child {
        margin-bottom: 0;
      }

      .left_block {
        display: flex;
        align-items: center;

        @media (max-width: 480px) {
          column-gap: 10px;
          width: 90%;
          // align-items: flex-start;
        }

        @media (min-width: 1199px) {
          width: 55%;
        }

        .card_img {
          margin-right: 10px;

          @media (max-width: 430px) {
            margin: 0;
          }
        }

        .card_details {
          h2 {
            font-weight: 600;
            font-size: 18px;
            line-height: 1.3;
            color: $white;
            margin-bottom: 12px;
          }

          p {
            font-size: 14px;
            line-height: 1.2;
            color: $white;
            opacity: 0.5;
            margin-bottom: 10px;
          }
        }
      }

      .right_block {
        @media (max-width: 430px) {
          text-align: center;
          margin-left: auto;
        }

        @media (min-width: 1199px) {
          padding-left: 1rem;
        }

        .sales {
          font-size: 14px;
          line-height: 1.2;
          color: $white;
          opacity: 0.5;
          margin-bottom: 10px;
          text-align: right;

          @media (max-width: 480px) {
            text-align: center;
          }
        }

        .price {
          display: flex;
          align-items: center;

          @media (max-width: 991px) {
            justify-content: end;
          }

          @media (max-width: 480px) {
            justify-content: center;
          }

          h2 {
            font-family: $Inter-SemiBold;
            font-weight: 600;
            font-size: 28px;
            line-height: 1.2;
            color: $white;

            @media (max-width: 1199px) {
              font-size: 22px;
            }

            &.old {
              text-decoration: line-through;
              opacity: 0.5;
              margin-right: 10px;
            }
          }
        }
      }

      .removeIcon {
        position: absolute;
        top: 6px;
        right: 6px;
        z-index: 5;
        cursor: pointer;
      }
    }

    .collection_item {
      .card_view {
        border: 1px solid #1e1e1e;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        margin-bottom: 15px;
        transition: all 0.3s;
        background: #181818;
        position: relative;
        transition: all 0.2s ease-in-out;

        .removeIcon {
          position: absolute;
          cursor: pointer;
          filter: brightness(1.5);
          top: 15px;
          right: 15px;
        }

        &:hover {
          background-color: #252525;
          border-color: $primary-color;

          .left_block > .visitBtn > button {
            border: 1px solid $primary-color;
            background-color: transparent;
            color: $primary-color;
          }
        }

        .left_block {
          display: flex;
          align-items: center;
          gap: 20px;
          width: 100%;

          img {
            width: 80px;
            height: 80px;
            display: block;
          }

          .card_details {
            width: 65%;
            h2 {
              font-size: 20px;
              font-weight: 600;
            }

            p {
              opacity: 0.5;
              font-size: 14px;
            }
          }
        }

        .visitBtn > button {
          padding: 6px 12px;
          border: 1px solid transparent;
          font-family: 'Inter-SemiBold';
          font-weight: 300;
          background-color: #ffb43a;
          transition: all 0.5s ease;
          text-transform: uppercase;
          text-align: center;
          color: #fff;
        }
      }
    }

    &.card_box_view {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      @media (max-width: 767px) {
        padding-left: 12px;
      }
      .single_card {
        max-width: calc(33.33% - 12px);
        width: 100%;
        padding: 0;
        @media (max-width: 1199px) {
          max-width: calc(50% - 12px);
        }

        @media (max-width: 575px) {
          max-width: calc(100% - 12px);
        }

        .card_view {
          display: block;
          text-align: center;
          margin-bottom: 0;
          .card_details {
            h2 {
              font-size: 16px;
            }
          }
        }

        .left_block {
          display: block;
        }

        .right_block {
          margin-top: 10px;

          .sales {
            text-align: center;
          }

          .price {
            justify-content: center;

            h2 {
              font-size: 22px;
            }
          }
        }
      }
    }

    &.card_box_view {
      .single_card {
        .left_block {
          .card_img {
            margin: 0 auto 15px;
          }
        }
      }
    }
  }

  &.download_block {
    .card_block {
      .single_card {
        .card_view {
          @media (max-width: 1199px) {
            display: block;
          }

          .right_block {
            display: flex;

            @media (max-width: 1199px) {
              margin-top: 15px;
            }

            @media (max-width: 480px) {
              display: block;
            }

            .btn_wrapper {
              min-width: 170px;

              @media (max-width: 1399px) {
                min-width: 150px;
              }
            }

            .review {
              margin-right: 15px;
              border-color: $white;

              @media (max-width: 480px) {
                margin-right: 0;
                margin-bottom: 10px;
                width: 100%;
              }

              &:hover {
                color: $white;
                border-color: $primary-color;
              }

              label {
                background-color: #1c1c1c;
              }
            }
          }
        }
      }
    }
  }

  &.collection_block {
    .topbar {
      @media (max-width: 767px) {
        display: flex;
      }
    }

    .card_block {
      &.card_box_view {
        .single_card {
          .left_block {
            .card_img {
              margin: 0 auto 15px;
            }
          }
        }
      }
    }

    .single_card {
      text-align: left;

      .menuIcon {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
      }

      .card_view {
        @media (max-width: 375px) {
          justify-content: center;
          text-align: center;
        }
      }

      .left_block {
        display: flex;
        align-items: center;

        @media (max-width: 375px) {
          display: block;
        }

        .card_img {
          width: 190px;
          height: 155px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #1e1e1e;
          border-radius: 14px;

          @media (max-width: 1199px) {
            width: 150px;
            height: 110px;
          }

          @media (max-width: 480px) {
            margin: 0;
            max-width: 70px !important;
            max-height: 70px !important;
            margin-right: 10px;
          }

          @media (max-width: 375px) {
            margin: 0 auto 15px;
          }
        }
      }
    }
  }
}

.reviewModal {
  .modal-header .btn-close {
    background: transparent url('../../assets/images/icon/close.svg');
    height: 24px;
    padding: 0;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    box-shadow: none;
    outline: none;
  }
}

.css-127ddrt-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  filter: none !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 535px;
    margin: 1.75rem auto;
  }
}
