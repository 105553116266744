@import "../../assets/scss/abstracts/variables";

.stepsMain_wrapper {
    .stepsMain {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        color: $white;

        @media screen and (max-width: 575px) {
            padding: 30px 16px;
        }

        .steps_block {
            width: 100%;
            max-width: 1130px;

            // Stepper Indicator CSS
            .MuiStepper-horizontal {
                margin: 40px 0 20px;
                column-gap: 16px;

                @media screen and (max-width: 575px) {
                    column-gap: 8px;
                }

                .MuiStep-horizontal {
                    padding: 0;
                }
            }

            .MuiStepLabel-horizontal {
                .MuiStepLabel-iconContainer {
                    display: none;
                }

                .Mui-active {
                    .step-indicator {
                        background-color: rgba(255, 180, 58, 0.4);
                    }
                }

                .Mui-completed {
                    .step-indicator {
                        background-color: #FFB43A;
                    }
                }
            }

            .MuiStepConnector-horizontal {
                display: none;
            }

            .step-indicator {
                display: block;
                width: 50px;
                height: 8px;
                background-color: #FFFFFF1A;
                border-radius: 10px;

                @media screen and (max-width: 575px) {
                    width: 30px;
                }
            }

            // Current Step out of Total Step
            .steps-count {
                margin-bottom: 30px;
            }

            // Stepper Body CSS
            .step-content {
                .stepHeading {
                    font-family: $clashdisplay-bold;
                    font-size: 45px;
                    line-height: 1.3;
                    letter-spacing: 0.025em;
                    max-width: 600px;
                    margin-bottom: 30px;
                    word-spacing: 10px;

                    @media screen and (max-width: 1199px) {
                        font-size: 36px;
                    }

                    @media screen and (max-width: 575px) {
                        font-size: 24px;
                        font-family: $clashdisplay-semibold;
                        word-spacing: 4px;
                        margin-bottom: 20px;
                    }
                }

                // Step: 1 CSS
                .website-wrapper {
                    background: #292929;
                    border-radius: 40px;
                    padding: 30px;
                    max-width: 900px;
                    margin-bottom: 60px;

                    @media screen and (max-width: 1399px) {
                        padding: 20px;
                    }

                    @media screen and (max-width: 575px) {
                        padding: 12px;
                        border-radius: 20px;
                    }

                    label {
                        width: 100%;
                        max-width: 100%;
                        position: relative;

                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            visibility: hidden;
                        }

                        .wrapper {
                            background: linear-gradient(160.91deg, rgba(255, 255, 255, 0.02) -20.72%, rgba(255, 255, 255, 0.07) 124.32%);
                            border: 2px solid #ffffff20;
                            border-radius: 24px;
                            padding: 25px;
                            cursor: pointer;

                            @media screen and (max-width: 1199px) {
                                padding: 16px;
                            }

                            @media screen and (max-width: 575px) {
                                border-radius: 14px;
                            }

                            &.active {
                                background: #444444;
                                border: 2px solid #444444;
                            }

                            img {
                                margin-bottom: 20px;

                                @media screen and (max-width: 1199px) {
                                    margin-bottom: 10px;
                                }
                            }

                            h6 {
                                font-size: 16px;
                                line-height: 1.3;
                                color: white;
                                $Inter-Regular : 'Inter-Regular';

                                @media screen and (max-width: 1199px) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }

                // Step: 2 CSS
                .industry-wrapper {
                    margin-bottom: 60px;
                    max-width: 900px;

                    .MuiInputLabel-formControl {
                        color: $white;
                    }

                    .MuiSelect-select {
                        color: $white;
                        position: relative;
                        z-index: 1;
                        // padding: 25px;
                    }

                    .MuiSvgIcon-root {
                        fill: $white;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        background: #29292990;
                        border: 1px solid #303030;
                        border-radius: 12px;
                        // padding: 25px;
                    }
                }

                // Step: 3 CSS
                .design-wrapper {
                    background: #292929;
                    border-radius: 40px;
                    padding: 30px 20px 30px;
                    margin-bottom: 50px;
                    max-width: 1130px;
                    height: 460px;
                    position: relative;

                    @media screen and (max-width: 1399px) {
                        max-width: max-content;
                    }

                    @media screen and (max-width: 575px) {
                        padding: 12px;
                        border-radius: 20px;
                    }

                    &::after {
                        content: '';
                        width: 100%;
                        height: 40%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: linear-gradient(180deg, rgba(41, 41, 41, 0) 35.1%, rgba(41, 41, 41, 0.85) 79.13%, #292929 100%);
                        border-radius: 40px;
                        pointer-events: none;
                    }

                    .inner-wrapper {
                        display: grid;
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                        gap: 20px;
                        flex-wrap: wrap;
                        overflow-y: scroll;
                        width: 100%;
                        height: 100%;
                        padding-right: 20px;

                        @media screen and (max-width: 1399px) {
                            grid-template-columns: auto auto;
                        }

                        @media screen and (max-width: 1199px) {
                            gap: 10px;
                        }

                        @media screen and (max-width: 575px) {
                            grid-template-columns: auto;
                            padding-right: 10px;
                        }

                        &::-webkit-scrollbar {
                            width: 6px;
                        }

                        &::-webkit-scrollbar-track {
                            background: #313030;
                            border-radius: 100px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #FFB43A;
                            border-radius: 100px;
                            height: 50px;
                        }
                    }

                    label {
                        position: relative;
                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                        }
                    }

                    .design-card {
                        background: linear-gradient(160.91deg, rgba(255, 255, 255, 0.02) -20.72%, rgba(255, 255, 255, 0.07) 124.32%);
                        border-radius: 40px;
                        padding: 20px;
                        position: relative;
                        border: 2px solid rgba(255, 255, 255, 0.1254901961);
                        max-width: max-content;
                        transition: all 0.3s ease-in-out;
                        cursor: pointer;

                        @media screen and (max-width: 1199px) {
                            padding: 12px;
                            border-radius: 20px;
                        }

                        &.active {
                            border: 2px solid rgba(255, 180, 58, 0.8);

                            .checkmark {
                                background-image: url('../../assets/images/icon/tick-circle-orange.svg');
                            }
                        }

                        .checkmark {
                            width: 44px;
                            height: 44px;
                            display: block;
                            background-image: url('../../assets/images/icon/tick-circle-black.svg');
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                            top: 30px;
                            left: 30px;
                            transition: background-image 0.3s ease-in-out;
                        }

                        .MuiCheckbox-root {
                            position: absolute;
                            top: 20px;
                            left: 20px;

                            &.Mui-checked {
                                svg {
                                    fill: #FFB43A;
                                }
                            }

                            svg {
                                fill: rgba(26, 26, 26, 0.47);
                            }
                        }

                        .designImg {
                            border-radius: 20px;
                            margin-bottom: 10px;

                            @media screen and (max-width: 575px) {
                                border-radius: 12px;
                            }
                        }

                        h6 {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 1.3;
                            max-width: max-content;

                            @media screen and (max-width: 1199px) {
                                font-size: 16px;
                            }
                        }

                        .sales-tag {
                            display: flex;
                            align-items: center;
                            column-gap: 10px;
                        }
                    }
                }

                // Step: 4 CSS
                .select-pages-wrapper {
                    background: #292929;
                    border: 1px solid #303030;
                    border-radius: 40px;
                    padding: 20px;
                    margin-bottom: 50px;
                    max-width: 910px;
                    height: 460px;
                    position: relative;

                    @media screen and (max-width: 575px) {
                        padding: 12px;
                        border-radius: 20px;
                    }

                    .inner-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        gap: 10px;
                        flex-wrap: wrap;
                        overflow-y: scroll;
                        width: 100%;
                        height: 100%;
                        padding-right: 10px;

                        &::-webkit-scrollbar {
                            width: 6px;
                        }

                        &::-webkit-scrollbar-track {
                            background: #313030;
                            border-radius: 100px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #FFB43A;
                            border-radius: 100px;
                            height: 50px;
                        }

                        label {
                            position: relative;
                            width: 100%;
                            max-width: 205px;
                            input {
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 0;
                            }
                        }

                        .page-card {
                            display: flex;
                            align-items: center;
                            column-gap: 10px;
                            background: #343434;
                            border: 2px solid #343434;
                            border-radius: 16px;
                            padding: 30px 15px;
                            width: 100%;
                            max-width: 205px;
                            cursor: pointer;

                            &.checked {
                                border: 2px solid #FFB43A;
                            }

                            h6 {
                                font-size: 14px;
                                line-height: 1.2;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                white-space: pre-wrap;
                            }
                        }
                    }
                }

                // Step: 5 CSS
                .form-wrapper {
                    margin-bottom: 50px;
                    width: 100%;
                    max-width: 900px;

                    .form-group {
                        margin-bottom: 20px;

                        .form-control {
                            background: #292929;
                            border: 1px solid #242424;
                            border-radius: 6px;
                            transition: all 0.3s ease-in-out;
                            box-shadow: none;
                            padding: 12px 20px;
                            color: $white;

                            &:focus {
                                border: 1px solid #FFB43A;
                            }
                        }

                        .react-tel-input {
                            .form-control {
                                padding: 12px 20px 12px 60px;
                                height: auto;
                                width: 100%;
                            }

                            .flag-dropdown {
                                background-color: transparent;
                                border: none;

                                .selected-flag {
                                    padding: 0 0 0 20px;
                                    background-color: transparent;
                                }
                            }

                            .country-list {
                                .country-name {
                                    color: black;
                                }
                            }
                        }

                        .social-group {
                            display: flex;
                            column-gap: 6px;

                            span {
                                background: #292929;
                                border: 1px solid #242424;
                                border-radius: 6px;
                                padding: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 50px;
                                height: 50px;
                            }
                        }

                        .fileUpload {
                            background: #292929;
                            border: 1px solid #242424;
                            padding: 12px;
                            position: relative;
                            border-radius: 6px;
                            display: flex;
                            align-items: center;
                            column-gap: 10px;

                            .img-wrapper {
                                width: 95px;
                                height: 95px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: #20201F;
                                border-radius: 12px;

                                img {
                                    &.uploadedImg {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        object-position: center;
                                        border-radius: 12px;
                                    }
                                }
                            }

                            .fileInput {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                opacity: 0;

                                // &::file-selector-button {
                                //     opacity: 0;
                                // }
                            }

                            .browse-btn {
                                margin-left: auto;
                                padding: 10px 30px;
                                color: $white;
                                border: 1px solid #303030;
                                border-radius: 9px;
                            }
                        }
                    }
                }

                // Step: 6 CSS
                .overview-wrapper {
                    background: #292929;
                    border: 1px solid #303030;
                    border-radius: 40px;
                    padding: 20px;
                    margin-bottom: 50px;
                    max-width: 900px;

                    @media screen and (max-width: 575px) {
                        padding: 12px;
                        border-radius: 20px;
                    }

                    .overview-main {
                        .inner-wrapper {
                            display: flex;
                            column-gap: 20px;
                            padding-bottom: 20px;
                            margin-bottom: 20px;
                            border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                            @media screen and (max-width: 1199px) {
                                flex-direction: column;
                                row-gap: 20px;
                            }

                            .design-card {
                                background: linear-gradient(160.91deg, rgba(255, 255, 255, 0.02) -20.72%, rgba(255, 255, 255, 0.07) 124.32%);
                                border-radius: 40px;
                                padding: 20px;
                                position: relative;
                                border: 2px solid rgba(255, 255, 255, 0.1254901961);
                                max-width: max-content;
                                transition: all 0.3s ease-in-out;
                                flex: 0 0 40%;

                                @media screen and (max-width: 1199px) {
                                    padding: 12px;
                                    border-radius: 20px;
                                }

                                &.active {
                                    border: 2px solid #FFB43A60;
                                }

                                .MuiCheckbox-root {
                                    position: absolute;
                                    top: 20px;
                                    left: 20px;

                                    &.Mui-checked {
                                        svg {
                                            fill: #FFB43A;
                                        }
                                    }

                                    svg {
                                        fill: rgba(26, 26, 26, 0.47);
                                    }
                                }

                                .designImg {
                                    border-radius: 20px;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;

                                    @media screen and (max-width: 575px) {
                                        border-radius: 12px;
                                    }
                                }
                            }

                            .details {
                                flex: 0 0 60%;

                                .designName {
                                    font-size: 24px;
                                    line-height: 1.3;
                                    padding-bottom: 20px;
                                    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                                    @media screen and (max-width: 575px) {
                                        font-size: 20px;
                                    }
                                }

                                .detail-group {
                                    padding: 20px 0;

                                    .info {
                                        margin-bottom: 20px;

                                        &:last-child {
                                            margin: 0;
                                        }

                                        .infoLabel {
                                            font-size: 16px;
                                            line-height: 1.2;
                                            opacity: 0.5;
                                        }

                                        .infoValue {
                                            font-size: 16px;
                                            line-height: 1.2;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .other-details {
                        .other-info {
                            display: flex;
                            justify-content: center;
                            column-gap: 10px;
                            align-items: center;

                            &.put-border {
                                border-right: 1px solid rgba(255, 255, 255, 0.05);

                                @media screen and (max-width: 575px) {
                                    border: 0;
                                }
                            }

                            .infoLabel {
                                font-size: 14px;
                                line-height: 1.2;
                                opacity: 0.5;
                            }

                            .infoValue {
                                font-size: 16px;
                                line-height: 1.2;
                            }
                        }
                    }
                }

                // Step:7 CSS
                .payment-wrapper {
                    margin-bottom: 50px;
                    width: 100%;
                    max-width: 900px;

                    .form-group {
                        margin-bottom: 20px;

                        .form-control {
                            background: transparent;
                            border: 1px solid #363636;
                            border-radius: 0;
                            transition: all 0.3s ease-in-out;
                            box-shadow: none;
                            padding: 12px 20px;
                            color: $white;

                            &:focus {
                                border: 1px solid #FFB43A;
                            }
                        }

                        .form-check-input {
                            // background: transparent;
                            border: 1px solid #363636;
                            border-radius: 0;
                            box-shadow: none;

                            &:focus {
                                border: 1px solid #FFB43A;
                            }
                        }
                    }

                    .total-payment {
                        margin-bottom: 30px;

                        tr {
                            font-size: 16px;
                            line-height: 1.3;

                            td {
                                padding: 10px 0;

                                &:first-child {
                                    color: #ffffff90;
                                }

                                &:last-child {
                                    text-align: right;
                                }
                            }

                            &:last-child {
                                border-top: 1px solid #ffffff70;

                                td {
                                    padding: 16px 0;

                                    &:last-child {
                                        font-size: 20px;
                                        line-height: 1.3;
                                    }
                                }
                            }
                        }
                    }

                    .submit-btn {
                        background-color: #FFB43A;
                        border: none;
                        outline: none;
                        color: #fff;
                        padding: 10px 30px;

                    }
                }
            }

            // Nav Buttons CSS (Back & Next Step)
            .btn-group {
                display: flex;
                column-gap: 20px;

                .step-btn {
                    border: none;
                    outline: none;
                    color: $white;
                    padding: 10px 30px;
                    &.back-btn {
                        background-color: #1D1D1D;
                    }

                    &.next-btn {
                        background-color: #FFB43A;
                    }
                }
                &[activestep="0"]{                    
                    .step-btn {
                        &.back-btn {
                           display: none;
                        }
                    }
                }
            }
        }

        .steps_img {
            @media screen and (max-width: 991px) {
                display: none;
            }
        }
    }
}