@import "../../assets/scss/abstracts/variables";

.refund_wrapper{
    padding: 80px 0;
    @media screen and (max-width:1199px) {
        padding: 60px 0;        
    }
    h2{
        font-size: 28px;
        color: #fff;
        opacity: 0.8;
        margin-top: 30px;
        @media screen and (max-width:1199px) {
            font-size: 24px;  
            margin-top: 25px;
        }
        @media screen and (max-width:767px) {
            font-size: 20px;
            margin-top: 25px;        
        }
        &:first-child{
            margin-top: 0;
        }
    }
    p{
        font-size: 15px;
        line-height: 22px;
        opacity: 0.5;
        font-weight: 400;
        margin-top: 8px;
        @media screen and (max-width:1199px) {
            font-size: 14px;
            line-height: 22px; 
            margin-top: 5px;  
        }
    }
}