.discover_wrapper {
    // padding: 40px 50px 100px;
    padding: 70px 50px 100px;

    @media screen and (max-width:1399px) {
        padding: 30px 50px 60px;
    }

    @media screen and (max-width:767px) {
        padding: 10px 12px 40px;
    }

    .discover_block {
        max-width: 1350px;
        margin: auto;
        padding: 0 15px;

        @media screen and (max-width:575px) {
            padding: 0px;
        }

        .discoverCard {
            background: #242424;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 6px;
            box-shadow: #000000 0 0 12px;
            transform: translate(0, 0);
            transition: all 0.3s ease;

            &:hover {
                transform: translate(0, -20px);

                @media screen and (max-width:767px) {
                    transform: inherit;
                }
            }

            @media screen and (max-width:991px) {
                padding: 20px;
            }

            @media screen and (max-width: 430px) {
                flex-direction: column-reverse;
                row-gap: 10px;
            }

            .textBlock {
                @media screen and (max-width: 430px) {
                    text-align: center;
                }
            }

            h2 {
                font-weight: 600;
                font-size: 32px;
                line-height: 39px;
                color: #FFFFFF;
                margin-bottom: 10px;

                @media screen and (max-width:1399px) {
                    font-size: 26px;
                    line-height: 32px;
                }

                @media screen and (max-width:991px) {
                    font-size: 22px;
                    line-height: 26px;
                    margin-bottom: 6px;
                }

                @media screen and (max-width:767px) {
                    font-size: 20px;
                    line-height: 24px;
                }

                @media screen and (max-width: 430px) {
                    text-align: center;
                }
            }

            p {
                @media screen and (max-width: 430px) {
                    text-align: center;
                }
            }

            a {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: rgba(255, 255, 255, 0.8);
                display: inline-flex;
                align-items: center;
                transition: all 0.5s ease;

                img {
                    margin-left: 6px;
                }

                &:hover {
                    color: #FFFFFF;
                }
            }

            .imgBlock {
                @media screen and (max-width:1399px) {
                    margin-left: 15px;

                    img {
                        width: 100px;
                    }
                }

                @media screen and (max-width:991px) {
                    img {
                        width: 75px;
                    }
                }
            }
        }
    }
}