@import "../../assets/scss/abstracts/variables";

.head-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    position: relative;
    z-index: 5;

    h2 {
        font-family: $clashdisplay-medium;
        font-size: 110px;
        line-height: 1.4;
        text-align: center;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(255, 255, 255, 0.5);

        @media screen and (max-width: 1199px) {
            font-size: 60px;
        }

        @media screen and (max-width: 575px) {
            font-size: 40px;
        }
    }

    p {
        font-size: 20px;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 20px;
        font-family: $poppins-regular;
    }

    .btn-group {
        display: flex;
        flex-direction: row;
        gap: 10px;

        @media screen and (max-width: 575px) {
            flex-direction: column;
        }

        a {
            border-radius: 0;
            width: auto;
        }
    }
}