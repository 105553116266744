@import '../../../assets/scss/abstracts/variables';

footer{
    position: relative;
    z-index: 1;
    @media (max-width:767px){
        margin-bottom: 60px;
    }
    .footer_techno_block{
        background: $primary-color url(../../../assets/images/techno-bkg.png) no-repeat;
        background-position: right 0 bottom;
        padding: 66px 50px;
        border-radius: 24px;
        margin-bottom: -110px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        @media screen and (max-width:1199px) {
            padding: 50px 40px;
        }
        @media screen and (max-width:991px) {
            text-align: center;
            &:after{
                content: "";
                background: $primary-color;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.9;
            }
        }
        @media screen and (max-width:767px) {
            padding: 30px;
        }
        .textBlock{
            max-width: 646px;
            @media screen and (max-width:1199px) {
                max-width: 440px;
            }
            @media screen and (max-width:991px) {
                position: relative;
                z-index: 2;
                max-width: 100%;
            }
            h2{
                font-family: $clashdisplay-semibold;
                font-weight: 600;
                font-size: 42px;
                line-height: 52px;
                letter-spacing: 0.04em;
                text-transform: capitalize;
                color: $white;
                margin-bottom: 15px;
                @media screen and (max-width:1199px) {
                    font-size: 28px;
                    line-height: 38px;
                }
            }
            p{
                color: $white;
                margin-bottom: 30px;
            }
            .btn_wrapper{
                border-color: $secondary-color;
                background-color: $secondary-color;
                color: $white;
                max-width: 220px;
                &:hover{
                    background-color: $primary-color;
                }
                @media screen and (max-width:991px) {
                    margin: auto;
                }
            }
        }
    }
    .footer_wrapper{
        background: $secondary-color;
        min-height: 347px;
        padding-top: 174px;
        .footer_block{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 60px;
            a{
                line-height: 0;
            }
            .icons{
                display: flex;
                align-items: center;
                a{
                    margin-right: 15px;
                    width: 35px;
                    height: 35px;
                    background: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    overflow: hidden;
                    position: relative;
                    transition: all 0.5s ease;
                    font-size: 0;
                    &:last-child{
                        margin-right: 0;
                    }
                    img{
                        position: relative;
                        z-index: 2;
                    }
                    &::after{
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background: $primary-color;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50% , -50%) scale(0);
                        transition: all 0.5s ease;
                        border-radius: 100%;
                    }
                    &:hover{
                        &::after{
                            transform: translate(-50% , -50%) scale(1);
                        }
                    }
                }
            }
        }
        .copyRight{
            padding: 20px 0;
            position: relative;
            text-align: center;
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 1px;
                background: #D9D9D9;
                opacity: 0.1;
            }
            a{
                color: $white;
                opacity: 0.5;
                transition: all 0.5s ease;
                &:hover{
                    color: $primary-color;
                    opacity: 1;
                }
            }
        }
    }
}