.shopify_popup_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 35px 10px 35px;
  background-color: #4e9d4e;
  max-width: max-content;
  margin: 0 auto;
  border-radius: 50px;
  text-align: center;
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
}
.shopify_popup_box p {
  color: #fff;
  font-size: 14px;
  font-family: 'Inter-SemiBold';
}
span.shopify_close_icon {
  display: flex;
  margin-left: 20px;
  cursor: pointer;
}

.shopify_inner_popup {
  position: fixed;
  top: auto;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
@media screen and (max-width: 767px) {
  .shopify_popup_box {
    padding: 6px 12px 6px 12px;
    max-width: 100%;
    justify-content: center;
  }
  .shopify_popup_box p {
    font-size: 12px !important;
    line-height: 16px;
  }
  .shopify_inner_popup {
    bottom: 65px;
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
  }
  span.shopify_close_icon {
    margin-left: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopify_popup_box p {
    font-size: 13px;
    line-height: 17px;
  }
  .shopify_popup_box {
    padding: 8px 25px 8px 25px;
    max-width: 100%;
  }
  .shopify_inner_popup {
    max-width: 460px;
    width: 100%;
  }
  span.shopify_close_icon {
    margin-left: 10px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .shopify_popup_box {
    padding: 9px 30px 9px 30px;
  }
}
