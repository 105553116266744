.contact-banner p {
  max-width: 800px;
}
.support_form_title h4.fw-bold.mb-2 {
  text-align: center;
}
.supportform {
  max-width: 980px;
  padding-top: 70px;
  margin: 0 auto;
}
.supportform .row {
  row-gap: 40px;
}
.supportform label {
  padding-bottom: 7px;
}
.support_image label {
  padding-bottom: 0;
  margin-left: 35px;
}
.support_image {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  max-width: 280px;
  margin-top: 10px;
}
.support_image button.btn {
  color: rgba(255, 180, 58, 1);
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  background-color: transparent;
  padding-left: 30px;
}
.support_image input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.support_image:before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: rgba(48, 48, 48, 1);
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../../assets/images/support_plue.svg');
  background-size: 9px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
}

.support_google_captcha iframe {
  padding-top: 0;
}
.support_google_captcha {
  padding-top: 30px;
  padding-bottom: 10px;
}
form.supportform .css-t3ipsp-control {
  border-color: #ffb43aed;
  box-shadow: 0 0 0 1px #ffb43aed;
}

@media screen and (max-width: 767px) {
  .supportform {
    padding-top: 30px;
  }
  .supportform label {
    padding-bottom: 0px;
    text-align: left;
    width: 100%;
    font-size: 14px;
  }
  .supportform .row {
    row-gap: 15px;
  }
  .support_image {
    display: flex;
  }
  .support_google_captcha {
    padding-top: 10px;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .supportform {
    padding-top: 30px;
  }
  .supportform .row {
    row-gap: 25px;
  }
  .support_google_captcha {
    padding-top: 10px;
    padding-bottom: 0;
  }
  .supportform label {
    padding-bottom: 5px;
    font-size: 15px;
  }
}
