
.announcement {
    background: #FFB43A;
    z-index: 99999;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 7px 0;

}
span.message {
    display: flex;
    margin-right: 12rem;
    align-items: center;
    color: #000;
    font-weight: 500;
}
span.message a {
    display: flex;
    color: #000;
    align-items: center;
}
span.message span.text {
    margin-left: 8px;
    font-size: 14px;
}
span.message span.icon{
    display: flex;
}
@media screen and (max-width: 992px){
    .announcement{
        padding: 5px 0;
    }
    span.message{
        margin-right: 4rem;
    }
    span.message span.text {
        margin-left: 8px;
        font-size: 14px;
    }
}