@import "../../assets/scss/abstracts/variables";

.formCustom {
    &.settings {
        @media screen and (max-width: 991px) {
            .row > div:first-child {
                border-bottom: 1px solid #363636;
            }
        }
        @media screen and (min-width: 992px) {
            .row > div:first-child {
                border-right: 1px solid #363636;
            }
        }
    }

    form{
        label {
            font-size: 16px;
            margin-bottom: 10px;
            color: $white;
            opacity: 0.5;
            font-family: $Inter-Regular;
        }
        .user-input{
            border: 1px solid #363636;
            background: transparent;
            border-radius: 0;
            outline: none;
            line-height: 19px;
            &:focus{
                border-color: $primary-color;
            }
            &::-webkit-input-placeholder,
            &::placeholder {
                color: $white;
                opacity: 0.5;
                font-weight: 400;
                @media(max-width: 767px) {
                    font-size: 14px;
                }
            }
        }               
        .btn_wrapper{
            &.full{
                width: 100%;
            }
            label{
                opacity: 1;
            }
        }
        .form-group{
            div{
                display: flex;
                @media screen and (max-width:1199px) {
                    display: block;
                }
                .btn_wrapper{
                    margin-left: 10px;
                    @media screen and (max-width:1199px) {
                        margin-left: 0;
                        width: 100%;
                        margin-top: 15px;
                    }
                }
            }
            p{
                font-family: $Inter-Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: $white;
                opacity: 0.3;
                margin-top: 7px;
            }
        }
    }
}

.itemModal{    
    .modal-confirm {		
        color: #636363;
        width: 400px;
    }
    .modal-confirm .modal-content {
        padding: 20px;
        border-radius: 5px;
        border: none;
        text-align: center;
        font-size: 14px;
    }
    .modal-confirm .modal-header {
        border-bottom: none;   
        position: relative;
    }
    .modal-confirm h4 {
        text-align: center;
        font-size: 26px;
        margin: 30px 0 -10px;
    }
    .modal-confirm .close {
        position: absolute;
        top: -5px;
        right: -2px;
    }
    .modal-confirm .modal-body {
        color: #999;
    }
    .modal-confirm .modal-footer {
        border: none;
        text-align: center;		
        border-radius: 5px;
        font-size: 13px;
        padding: 10px 15px 25px;
    }
    .modal-confirm .modal-footer a {
        color: #999;
    }		
    .modal-confirm .icon-box {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        border-radius: 50%;
        z-index: 9;
        text-align: center;
        border: 3px solid #f15e5e;
    }
    .modal-confirm .icon-box i {
        color: #f15e5e;
        font-size: 46px;
        display: inline-block;
        margin-top: 13px;
    }
    .modal-confirm .btn, .modal-confirm .btn:active {
        color: #fff;
        border-radius: 4px;
        background: #60c7c1;
        text-decoration: none;
        transition: all 0.4s;
        line-height: normal;
        min-width: 120px;
        border: none;
        min-height: 40px;
        border-radius: 3px;
        margin: 0 5px;
    }
    .modal-confirm .btn-secondary {
        background: #c1c1c1;
    }
    .modal-confirm .btn-secondary:hover, .modal-confirm .btn-secondary:focus {
        background: #a8a8a8;
    }
    .modal-confirm .btn-danger {
        background: #f15e5e;
    }
    .modal-confirm .btn-danger:hover, .modal-confirm .btn-danger:focus {
        background: #ee3535;
    }
    .trigger-btn {
        display: inline-block;
        margin: 100px auto;
    }
}