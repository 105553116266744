.main-wrapper {
    flex: 1 0 auto;
    position: relative;
    // overflow: hidden;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-x: hidden;
    font-weight: normal;
    // overflow-y: auto !important;
    padding-left: 0 !important;
}

html {
    height: 100%;
}

section {
    margin-bottom: 100px;

    @media screen and (max-width:1399px) {
        margin-bottom: 60px;
    }

    @media(max-width: 991px) {
        margin-bottom: 50px;
    }

    @media(max-width: 767px) {
        margin-bottom: 40px;
    }
}

p {
    @media(max-width: 767px) {
        font-size: 15px !important;
    }

    @media(max-width: 575px) {
        font-size: 15px !important;
    }
}

form {
    textarea {
        min-height: 160px;
        max-height: 160px;
    }
    .error {
        font-size: 14px;
        color: rgb(255, 82, 82);
        margin: 5px 0;
    }

    .user-input {
        padding: 16px 20px 15px;
        line-height: 1;
        transition: 0.3s all;
        border-radius: 0;
        width: 100%;
        display: block;
        color: $white;
        background-color: #1C1C1C;
        border: 1px solid #363636;
        font-size: 16px;
        line-height: 19px;
        font-family: $Inter-Regular;
        border-radius: 0;

        // margin-bottom: 20px;
        @media(max-width: 575px) {
            padding: 9px 15px 11px;
        }

        &:focus {
            border-color: $primary-color;
            box-shadow: none;
            color: $white;
            background-color: transparent;
        }

        &::-webkit-input-placeholder,
        &::placeholder {
            color: $white;
            opacity: 0.4;
            font-weight: 400;
            @media(max-width: 767px) {
                font-size: 14px;
            }
        }

    }

    .checkBox {
        position: relative;
        margin-bottom: 15px;
        margin-top: 15px;

        .custom-checkbox {
            display: block;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            user-select: none;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.02em;
            font-size: 12px;
            line-height: 18px;
            color: $white;

            @media screen and (max-width: 575px) {
                text-align: justify;
                padding-left: 20px;
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
                border: 1px solid #ADADAD;
                background: #ADADAD;

                &:after {
                    left: 5px;
                    top: -1px;
                    width: 5px;
                    height: 12px;
                    border: solid $primary-color;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    content: "";
                    position: absolute;
                    display: none;
                }
            }

            input:checked~.checkmark {
                border-color: $primary-color;
                background: transparent;
            }

            input:checked~.checkmark:after {
                display: block;
            }
        }
    }
}

input,
textarea,
select {
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        box-shadow: 0 0 0 1000px transparent inset !important;
        -webkit-text-fill-color: $white !important;
        transition: background-color 5000s ease-in-out 0s;
    }
}

// .modal {
//     z-index: 99999 !important;
//     input,textarea,select {
//         &:-webkit-autofill{
//             -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
//             box-shadow: 0 0 0 1000px #fff inset !important;
//         }
//     }
//     .modal-dialog{
//         pointer-events: unset;
//         &.modal-lg{
//             @media(min-width: 992px){
//                 max-width: 720px;
//             }
//         }
//         .modal-div{
//             position: relative;

//         }
//         .close{
//             width: 30px;
//             height: 30px;
//             opacity: 1;
//             position: absolute;
//             right: 15px;
//             top: 15px;
//             cursor: pointer;
//             z-index: 2;
//             @include both-center;
//             @media (max-width: 991px){
//                 right: 0;
//                 top: 0;
//             }
//             @media (max-width: 767px) {
//                 width: 20px;
//                 height: 20px;
//                 right: 5px;
//                 top: 5px;
//             }
//         }
//         .modal-wrapper{
//             background: #FFFFFF;
//             border: none;
//             border-radius: 18px;
//             padding: 30px;
//             display: flex;
//             @media(max-width: 991px){
//                 display: block;
//                 padding: 20px;
//             }

//             .modal-content {
//                 position: relative;
//                 border: 0;
//                 padding: 0;
//                 text-align: center;
//                 border-radius: 18px;
//                 display: block;
//                 width: auto;
//             } 
//             .modal-header{
//                 justify-content: center;
//                 border: none;
//                 border-radius: 11px;   
//                 // background-image: url(../images/welcome-img.png);
//                 background-size: cover;
//                 height: 100%;
//                 width: 240px;
//                 padding: 0;
//                 @media(max-width: 991px){
//                     width: 100%;
//                     background-image: none;
//                 }
//                 .logo{
//                     line-height: 0;
//                     @media(max-width: 991px){
//                         padding: 20px 0;
//                     }
//                     @media(max-width: 575px){
//                         max-width: 180px;
//                     }
//                 }
//             }
//             .modal-body {
//                 padding: 30px 0 30px 25px;
//                 @media(max-width: 991px){
//                     padding: 0;
//                     margin-top: 20px;
//                     text-align: center;
//                 }
//                 .content {
//                     h2 {
//                         color: $white;
//                         font-weight: 700;
//                         font-size: 28px;
//                         line-height: 1.5;
//                         margin-bottom: 20px;
//                         @media (max-width: 991px) {
//                             margin-bottom: 15px;
//                         }
//                         @media (max-width: 767px) {
//                             font-size: 24px;
//                             line-height: 36px;
//                         } 
//                         @media(max-width: 480px) {
//                             font-size: 22px;
//                             line-height: 32px;
//                         }
//                     }
//                     p {
//                         font-size: 16px;
//                         line-height: 26px;
//                         color: $text-color;
//                         margin-bottom: 20px;
//                         @media (max-width: 767px) {
//                             text-align: center;
//                         }
//                         @media (max-width: 480px) {
//                             font-size: 15px;
//                             line-height: 24px;
//                             margin-bottom: 15px;
//                         }
//                         a {
//                             // color: $secondary-color;
//                             transition: 0.3s all;
//                             &:hover{
//                                 color: $primary-color;
//                             }
//                         }
//                     }
//                 }

//             }
//         }
//     }
// }

.modal-content {
    border-radius: 0;
    background: #1C1C1C;
    border: none;
    padding: 60px;

    @media(max-width: 575px) {
        padding: 40px;
    }

    .modal-header {
        justify-content: center;
        padding: 0;
        margin-bottom: 40px;
        border-bottom: none;
    }

    .review {
        text-align: center;
        margin-bottom: 30px;

        h3 {
            font-size: 22px;
            margin-bottom: 10px;

            @media(max-width: 575px) {
                font-size: 18px;
            }
        }

        span {
            font-size: 40px;
        }
    }

    form {
        .form-label {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $white;
            opacity: 0.5;
            margin-bottom: 10px;
        }

        .btn {
            width: 100%;
        }
    }
}

.modal-backdrop.show {
    background: rgba(20, 20, 20, 0.8);
    backdrop-filter: blur(5px);
    opacity: 1;
}

.btn_wrapper {
    position: relative;
    min-width: 175px;
    padding: 12px 12px;
    border: 1px solid transparent;
    font-family: $Inter-SemiBold;
    font-weight: 300;
    transition: 0.5s;
    letter-spacing: 1px;
    display: block;
    background-color: $primary-color;
    transition: all 0.5s ease;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    border-radius: 5px;

    &:hover {
        border-color: $primary-color;
        background-color: transparent;
        color: $primary-color;
    }

    &.light {
        background-color: $white;
        border: 1px solid transparent;
        color: $black;

        &:hover {
            border-color: $white;
            background-color: transparent;
            color: white;
        }
    }
}

.highlight {
    border-color: red !important;
}

/* PAGE HEADING CSS START FROM HERE */
.heading {
    h2 {
        font-family: $clashdisplay-semibold;
        font-weight: 600;
        font-size: 48px;
        line-height: 59px;
        letter-spacing: 0.02em;
        color: $white;
        margin-bottom: 14px;

        @media screen and (max-width:1399px) {
            font-size: 38px;
            line-height: 48px;
        }

        @media screen and (max-width:1199px) {
            font-size: 32px;
            line-height: 44px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        span {
            display: block;
            font-family: $Inter-Medium;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $white;
        }
        
    }

    p {
        opacity: 0.7;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        li {
            padding-left: 40px;
            position: relative;
            min-height: 32px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding-bottom: 15px;

            &:after,
            &::before {
                content: "";
                position: absolute;
                left: 0;
            }

            &::before {
                background: url(../../images/icon/shield-done.svg) no-repeat;
                width: 32px;
                height: 32px;
                display: block;
                margin-right: 8px;
                top: 0;
            }

            &:after {
                width: 100%;
                height: 1px;
                background: #D9D9D9;
                opacity: 0.1;
                bottom: 0;
            }

            &:last-child {
                margin: 0;
                padding-bottom: 0;

                &::after {
                    content: none;
                }
            }
        }
    }

    &.small {
        h2 {
            font-size: 32px;
            line-height: 39px;
            letter-spacing: 0.05em;

            @media screen and (max-width:767px) {
                font-size: 28px;
                line-height: 38px;
            }

            @media screen and (max-width:575px) {
                font-size: 24px;
                line-height: 34px;
            }
        }
    }

    &.large {
        h2 {
            font-size: 62px;
            line-height: 78px;
            letter-spacing: 0.05em;

            @media screen and (max-width:1399px) {
                font-size: 53px;
                line-height: 64px;
            }

            @media screen and (max-width:1199px) {
                font-size: 38px;
                line-height: 54px;
            }

            @media screen and (max-width:767px) {
                font-size: 30px;
                line-height: 44px;
            }

            @media screen and (max-width:575px) {
                font-size: 22px;
                line-height: 30px;
            }
        }

        h1 {
            font-size: 53px;
            line-height: 78px;
            letter-spacing: 0.05em;

            @media screen and (max-width:1399px) {
                font-size: 53px;
                line-height: 64px;
            }

            @media screen and (max-width:1199px) {
                font-size: 38px;
                line-height: 54px;
            }

            @media screen and (max-width:767px) {
                font-size: 30px;
                line-height: 44px;
            }

            @media screen and (max-width:575px) {
                font-size: 22px;
                line-height: 30px;
            }
        }
    }
}

/* PAGE HEADING CSS END'S FROM HERE */

/* LOADER CSS */
.loaderSVG {
    // font-family: 'Russo One', sans-serif;
    position: absolute;
    width: 100%;
    height: 100%;

    text {
        text-transform: uppercase;
        animation: stroke 2s infinite alternate;
        stroke-width: 2;
        stroke: #ffffff;
        font-size: 100px;
    }
}

@keyframes stroke {
    0% {
        fill: rgba(72, 138, 20, 0);
        stroke: rgb(255, 255, 255);
        stroke-dashoffset: 25%;
        stroke-dasharray: 0 50%;
        stroke-width: 2;
    }

    70% {
        fill: rgba(72, 138, 20, 0);
        stroke: rgb(255, 255, 255);
    }

    80% {
        fill: rgba(72, 138, 20, 0);
        stroke: rgb(255, 255, 255);
        stroke-width: 3;
    }

    100% {
        fill: rgb(255, 255, 255);
        stroke: rgba(54, 95, 160, 0);
        stroke-dashoffset: -25%;
        stroke-dasharray: 50% 0;
        stroke-width: 0;
    }
}