@import "../../assets/scss/abstracts/variables";

.formCustom {
    input{
        color: #fff !important;
    }
    .btn_wrapper {
        margin-left: auto;

        @media(max-width: 991px) {
            margin: auto;
        }
    }

    .input-wrapper {
        position: relative;

        img {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            opacity: 0.6;
            cursor: pointer;
        }
    }

    .css-13cymwt-control {
        padding: 6px;
        border: 1px solid #363636;
        background-color: transparent;

        &:hover {
            border-color: #363636;
        }
    }

    .css-1nmdiq5-menu {
        background-color: #2c2c2c;
    }

    .css-13cymwt-control,
    .css-1nmdiq5-menu {
        border-radius: 0;

        &::placeholder {
            color: #808080;
            opacity: 1;
        }
    }

    .css-16wnbvk-control {
        background-color: transparent;
        border-radius: 0;
        padding: 6px;
        border: 1px solid #363636;
        box-shadow: none;
        z-index: 999999999;
    }

    .css-166bipr-Input {
        color: #ffffff;
    }

    .css-1dimb5e-singleValue {
        color: #ffffff;
    }
}
input#react-select-2-input {
    color: #fff !important;
}