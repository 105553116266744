.cartBtn {
    background: #ffffff26;
    margin-left: 8px;

    img {
        // width: 40px;
    }

    &.incart {
        background: #ffb43a !important;
        border: 1px solid #ffb43a !important;
        opacity: 1 !important;
    }
}

.cartBtn,
.previewBtn,
.favouriteBtn {
    padding: 4px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    transition: opacity 0.5s ease;
    text-transform: capitalize;
    color: #0f1014;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    // overflow: hidden;
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
    &:hover {
        // transform: scale(1.01);
    }
    img {
        width: 20px;
        height: 20px;
    }
}

.productWrapper .productBlocks .product .productPrice .cartBtn {
    width: 36px;
    height: 36px;

    span {
        width: 20px;
        height: 20px;
    }
}

.category-bottom {
    .btn_wrapper {
        display: none;
    }

    .loaderCircle {
        width: 20px;
        height: 20px;
    }

}