@import '../scss/abstracts/variables';
@import '../scss/abstracts/mixins';
@import '../scss/base/base';
@import '../scss/base/default';

.christmas-web {
  margin: 0 auto 50px;
  display: flex;
  justify-content: center;
  width: 95%;
  @media (max-width: 575px) {
    display: none;
  }
}

.christmas-web.prod-page {
  margin: 0;
  display: flex;
  justify-content: center;
  position: relative;
  top: -130px;
  z-index: 999;
  @media (max-width: 575px) {
    display: none;
  }

  @media (min-width: 575px) and (max-width: 768px) {
    top: -105px;
  }
}

.christmas-mob {
  margin: 0 auto 50px;
  display: flex;
  justify-content: center;
  width: 85%;
  @media (min-width: 575px) {
    display: none;
  }
}

.christmas-mob.prod-page {
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  top: -130px;
  z-index: 999;
  padding: 0 15px;
  @media (min-width: 575px) {
    display: none;
  }
}


.billingAddress p {
  color: white !important;
}