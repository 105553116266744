.contact {
  &-banner {
    background: #000 url('../../assets/images/background/bkg-1.webp');
    padding-block: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%),
        linear-gradient(
          180deg,
          rgba(20, 20, 20, 0.6) 0%,
          rgba(20, 20, 20, 0.11) 21.88%,
          rgba(20, 20, 20, 0) 100%
        ),
        linear-gradient(
          271deg,
          rgba(20, 20, 20, 0.09) 0.81%,
          rgba(20, 20, 20, 0.72) 68.38%,
          rgba(20, 20, 20, 0.846) 99.09%
        );
      left: 0;
      right: 0;
      top: 0;
      bottom: -5px;
    }

    & * {
      z-index: 2;
    }

    h1 {
      margin-top: 5rem;
    }

    p {
      width: 80%;
      line-height: 1.2rem;
    }

    @media (min-width: 1024px) {
      p {
        width: 55%;
        line-height: 1.5rem;
      }
    }
  }

  &-container {
    padding-block: 80px;

    @media (max-width: 767px) {
      padding-block: 50px;
      text-align: center;
    }

    .first-col {
      & > p {
        width: 60%;
        opacity: 50%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      ul {
        gap: 3rem;
        flex-direction: column;

        @media (max-width: 767px) {
          display: inline-flex !important;
          text-align: left;
        }

        li {
          gap: 1rem;

          .img_wrapper {
            width: 60px;
            height: 60px;
            flex: 0 0 60px;
            overflow: hidden;

            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }

          .details {
            height: fit-content;

            p {
              opacity: 0.7;
            }

            p:first-child {
              opacity: 1;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .second-col {
      form > div {
        gap: 0.8rem;
        margin-bottom: 30px;
      }

      input,
      textarea,
      select,
      .select__control,
      .select__menu {
        background-color: #2c2c2c;

        &::placeholder {
          color: #808080;
          opacity: 1;
        }
      }

      select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
      }

      textarea {
        resize: none;
        height: 150px;
      }

      .select__control {
        padding: 8px;

        @media (max-width: 575px) {
          padding: 0;
        }
      }

      .select__single-value,
      .select__input-container {
        color: #ffffff;
        display: flex;
      }

      .select__control,
      .select__menu {
        border-radius: 0;
        border: 1px solid #363636;
      }

      .select__placeholder {
        text-align: left;
      }

      @media (max-width: 767px) {
        .btn_wrapper {
          margin: auto;
        }
      }
    }
  }
}
#contactForm p.error {
  margin-top: -4px;
  line-height: 18px;
  margin-bottom: 0;
}
